import { zodResolver } from '@hookform/resolvers/zod';
import { ReactNode } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';

import { Button } from '@shared/ui/atoms/button';
import { Input } from '@shared/ui/atoms/input';
import { MailIcon } from '@shared/ui/icon';
import { ControlPageTemplate } from '@shared/ui/templates/control-page-template';
import { styled, useTheme } from '@shared/ui/theme';
import { Typography } from '@shared/ui/typography';

import img from './assets/img.webp';

const PRIVACY_LINK = 'http://mooni.health.tilda.ws/privacy';

type Props = {
    HeaderComponent: ReactNode;
    onContinue: () => void;
};

const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 20px;
    gap: 8px;
`;

const DescriptionWrapper = styled.div`
    margin-top: 12px;
`;

type TFormData = {
    email: string;
};

const schema = z.object({
    email: z
        .string({ required_error: 'Check your email is correct' })
        .min(1, 'Check your email is correct')
        .email('Check your email is correct'),
});

export const EmailPage = ({ HeaderComponent, onContinue }: Props) => {
    const theme = useTheme();
    const methods = useForm<TFormData>({
        mode: 'onChange',
        resolver: zodResolver(schema),
    });

    return (
        <ControlPageTemplate
            HeaderComponent={HeaderComponent}
            ContentComponent={
                <>
                    <img src={img} alt="" width="186px" height="189px" />
                    <InfoWrapper>
                        <Typography variant="title1">
                            Your Personalized <br /> Insights Are Here!
                        </Typography>
                        <Typography variant="body1">
                            Enter email to get started
                        </Typography>
                    </InfoWrapper>
                    <FormProvider {...methods}>
                        <form
                            onSubmit={methods.handleSubmit(onContinue)}
                            style={{ width: '100%' }}>
                            <Controller
                                control={methods.control}
                                name="email"
                                render={({ field }) => (
                                    <Input
                                        controlName="email"
                                        startIcon={<MailIcon />}
                                        placeholder={'E-mail'}
                                        {...field}
                                    />
                                )}
                            />
                        </form>
                    </FormProvider>
                    <DescriptionWrapper>
                        <Typography
                            variant="footnote"
                            color={theme.colors.text.secondary}>
                            Your privacy matters. We use advanced security to
                            safeguard your data. Information you share is solely
                            for personalized support on your well-being journey.
                            Trust is key, and we uphold the highest standards of
                            privacy and data security.
                            <a href={PRIVACY_LINK}>
                                <Typography
                                    variant="footnote"
                                    color={theme.colors.text.primary}>
                                    Privacy policy
                                </Typography>
                            </a>
                        </Typography>
                    </DescriptionWrapper>
                </>
            }
            ControlComponent={
                <Button
                    onClick={onContinue}
                    variant="contained"
                    fullWidth
                    disabled={!methods.formState.isValid}>
                    Continue
                </Button>
            }
        />
    );
};
