import { TQuestionInfo } from '@entities/questions/model/types';
import { CalendarForm } from '@entities/questions/ui/calendare-form';
import { Button } from '@shared/ui/atoms/button';
import { Input } from '@shared/ui/atoms/input';
import { RadioGroup } from '@shared/ui/molecules/radio-group';
import { mapIcon } from '@shared/utils/mappers/icon-mapper';
//TODO add logic to form

export const mapControl = (
    info: TQuestionInfo,
    onSubmit: (value: number | string) => void,
    controlName: 'integer' | 'date' | 'email',
) => {
    switch (info.type) {
        case 'INPUT':
            return (
                <>
                    <Input
                        startIcon={mapIcon(info.controlValue.icon)}
                        placeholder={info.controlValue.placeholder}
                        controlName={controlName}
                        defaultValue={info.controlValue.defaultValue}
                        helperText="Оn average, the maximum cycle can last from 23 to 35 days"
                    />

                    <Button
                        type="submit"
                        style={{ marginBottom: '16px' }}
                        fullWidth>
                        Continue
                    </Button>
                </>
            );
        case 'CALENDAR':
            return <CalendarForm onSubmit={onSubmit} />;
        case 'RADIO':
            return (
                <RadioGroup
                    items={info.controlValue.variants.map(item => ({
                        ...item,
                        icon: mapIcon(item.icon),
                    }))}
                    onSubmit={onSubmit}
                />
            );
        case 'MULTIPLE_RADIO':
            return (
                <RadioGroup
                    isMultiChose
                    items={info.controlValue.variants.map(item => ({
                        ...item,
                        icon: mapIcon(item.icon),
                    }))}
                    onSubmit={onSubmit}
                />
            );
        default:
            return <></>;
    }
};
