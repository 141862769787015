import { useStore } from 'effector-react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useGetStage } from '@entities/questions/model/hooks';
import {
    $stage,
    setAnswer,
    setStage,
} from '@entities/questions/model/questions-domain';
import { paths } from '@shared/paths';

import { ControlProvider } from './control-provider';
import { mapControl } from './mappers/control-map';
import { Quiz } from './quiz';

export const QuizConnector = () => {
    const currentStage = useStore($stage);
    const stage = useGetStage(currentStage);
    const navigate = useNavigate();
    const nextStage = useCallback(() => {
        if (stage?.isLast) {
            navigate(paths.suggestions);
            return;
        }
        setStage(currentStage + 1);
    }, [currentStage, navigate, stage?.isLast]);

    const saveAnswer = useCallback(
        (value: string | number) => {
            if (!stage) {
                return;
            }
            setAnswer({ [stage?.info.number]: String(value) });
        },
        [stage],
    );

    const getControl = useCallback(() => {
        if (!stage || stage.type !== 'QUESTION') {
            return null;
        }
        return mapControl(
            stage.info,
            value => {
                saveAnswer(value);
                nextStage();
            },
            (stage.info.type === 'INPUT' && stage.info.controlValue.schema) ||
                'integer',
        );
    }, [stage, saveAnswer, nextStage]);

    if (!stage) {
        return null;
    }
    const schemaType =
        stage.type === 'QUESTION' &&
        stage.info.type === 'INPUT' &&
        stage.info.controlValue.schema;
    return (
        <Quiz
            currentStage={currentStage}
            stage={stage}
            nextStage={nextStage}
            Control={
                <ControlProvider
                    stageNumber={stage.info.number}
                    nextStage={nextStage}
                    schemaType={schemaType || null}>
                    {getControl()}
                </ControlProvider>
            }
        />
    );
};
