import { RouteObject } from 'react-router-dom';

import { paths } from '@shared/paths';
import { MetaTitle } from '@shared/ui/atoms/meta-title';
import { PageTemplate } from '@shared/ui/templates/page-template';

import { EmailPageConnector } from './email-page-connector';

export const routes: RouteObject[] = [
    {
        path: paths.email,
        element: (
            <PageTemplate>
                <MetaTitle>Mooni</MetaTitle>
                <EmailPageConnector />
            </PageTemplate>
        ),
    },
];
