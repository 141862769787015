import { ThemedStyledProps } from 'styled-components';

import { breakpoints } from './dimensions';
import { TButtons, TColors, TRadioButtons, ThemeType } from './types';

/** Reference https://www.figma.com/file/9VE3HFDSHaJ2dsYrudQ7H3/%D0%9A%D0%B2%D0%B8%D0%B7-%D1%81%D0%B0%D0%BC%D0%B0%D0%BD%D1%82%D0%B0?node-id=270%3A19954&mode=dev */

const colorsLight: TColors = {
    name: 'light',
    background: {
        primary: '#FFFFFF',
        coral: '#FDF9F9',
    },
    surface: {
        primary: '#F3F3F3',
        secondary: '#3131310F',
        negativeLight: '#EB57571A',
    },
    text: {
        primary: '#222222F0',
        secondary: '#222222B2',
        tertiary: '#2222224D',
        brand: '#FF9494',
        invertPrimary: '#FFFFFF',
        invertSecondary: '#F3F3F3',
        negative: '#EB5757',
    },
    icon: {
        primary: '#222222F0',
        secondary: '#222222B2',
        tertiary: '#2222224D',
        brand: '#FF9494',
        invertPrimary: '#FFFFFF',
        invertSecondary: '#F3F3F3',
    },
    radioButton: {
        primary: '#F3F3F3',
        press: '#FF9494',
    },
    buttonBrand: {
        enabled: 'linear-gradient(213deg, #FF92A5 6.9%, #FFA592 93.1%)',
        pressed: 'linear-gradient(213deg, #FF7C87 6.9%, #FFA592 93.1%)',
        disabled: 'linear-gradient(31deg, #FFD4CA 7.49%, #FFCAD4 92.51%)',
    },
    iconButton: {
        pressed: '#FFE8E8',
    },
};

const buttons: TButtons = {
    contained: {
        enabled: {
            color: colorsLight.text.invertPrimary,
            backgroundColor: colorsLight.buttonBrand.enabled,
        },
        pressed: {
            color: colorsLight.text.invertPrimary,
            backgroundColor: colorsLight.buttonBrand.pressed,
        },
        disabled: {
            color: colorsLight.text.invertSecondary,
            backgroundColor: colorsLight.buttonBrand.disabled,
        },
    },
    pulsar: {
        enabled: {
            color: colorsLight.text.invertPrimary,
            backgroundColor: colorsLight.buttonBrand.enabled,
        },
        pressed: {
            color: colorsLight.text.invertPrimary,
            backgroundColor: colorsLight.buttonBrand.pressed,
        },
        disabled: {
            color: colorsLight.text.invertSecondary,
            backgroundColor: colorsLight.buttonBrand.disabled,
        },
    },
    icon: {
        enabled: {
            color: colorsLight.icon.brand,
            backgroundColor: 'transparent',
        },
        pressed: {
            color: colorsLight.icon.brand,
            backgroundColor: colorsLight.iconButton.pressed,
        },
        disabled: {
            color: colorsLight.icon.tertiary,
            backgroundColor: 'transparent',
        },
    },
    text: {
        enabled: {
            color: colorsLight.text.secondary,
            backgroundColor: 'transparent',
        },
        pressed: {
            color: colorsLight.text.secondary,
            backgroundColor: 'transparent',
        },
        disabled: {
            color: colorsLight.text.tertiary,
            backgroundColor: 'transparent',
        },
    },
};

const radioButtons: TRadioButtons = {
    enabled: {
        color: colorsLight.text.primary,
        backgroundColor: colorsLight.radioButton.primary,
    },
    pressed: {
        color: colorsLight.text.invertPrimary,
        backgroundColor: colorsLight.radioButton.press,
    },
    disabled: {
        color: colorsLight.text.invertSecondary,
        backgroundColor: 'transparent',
    },
};

export type TColorsSet = Record<ThemeType, TColors>;

export const colors: TColorsSet = {
    light: colorsLight,
};

export const theme = (c: TColors) => ({
    colors: c,
    breakpoints,
    buttons,
    radioButtons,
    space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
});

export type Theme = ReturnType<typeof theme>;

export type ThemedProps<P> = ThemedStyledProps<P, Theme>;
