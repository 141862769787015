export const CalendarHeartIcon = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.5 21H6C5.46957 21 4.96086 20.7893 4.58579 20.4142C4.21071 20.0391 4 19.5304 4 19V7C4 6.46957 4.21071 5.96086 4.58579 5.58579C4.96086 5.21071 5.46957 5 6 5H18C18.5304 5 19.0391 5.21071 19.4142 5.58579C19.7893 5.96086 20 6.46957 20 7V11H4M16 3V7M8 3V7M18 22L21.35 18.716C21.5554 18.5167 21.7188 18.2782 21.8306 18.0146C21.9423 17.7511 22.0001 17.4678 22.0006 17.1816C22.0011 16.8953 21.9442 16.6119 21.8333 16.3479C21.7224 16.084 21.5598 15.845 21.355 15.645C20.9371 15.2363 20.3762 15.007 19.7917 15.0059C19.2072 15.0048 18.6454 15.2319 18.226 15.639L18.002 15.859L17.779 15.639C17.3612 15.2306 16.8005 15.0015 16.2162 15.0004C15.632 14.9993 15.0704 15.2262 14.651 15.633C14.4455 15.8323 14.282 16.0707 14.1702 16.3342C14.0583 16.5977 14.0004 16.881 13.9999 17.1672C13.9993 17.4535 14.0561 17.737 14.1669 18.0009C14.2777 18.2648 14.4403 18.5039 14.645 18.704L18 22Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
