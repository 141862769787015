import { useNavigate } from 'react-router-dom';

import { paths } from '@shared/paths';
import { HeaderConnector } from '@widgets/header';

import { PaywallPage } from './paywall-page';

export const PaywallPageConnector = () => {
    const navigate = useNavigate();
    return (
        <PaywallPage
            HeaderComponent={
                <HeaderConnector
                    isReturnable={false}
                    isSkippable={false}
                    isTransparent={false}
                />
            }
            onContinue={() => navigate(paths.result)}
        />
    );
};
