import { Activity } from '@entities/questions/ui/activity';
import { Congratulations } from '@entities/questions/ui/congratulation';
import { Graphic } from '@entities/questions/ui/graphic';
import { Research } from '@entities/questions/ui/research';
import { TrackingApp } from '@entities/questions/ui/tracking-app';

import { THeroCode } from '../../types';

export const mapHero = (heroCode: THeroCode) => {
    switch (heroCode) {
        case 'ACTIVITY':
            return <Activity />;
        case 'TRACKING_APP':
            return <TrackingApp />;
        case 'GRAPHIC':
            return <Graphic />;
        case 'RESEARCH':
            return <Research />;
        case 'CONGRATULATIONS':
            return <Congratulations />;
        default:
            return <></>;
    }
};
