import { ReactNode } from 'react';

import { styled } from '@shared/ui/theme';

type TItem = {
    title: string;
    description: string;
};

type TProps = {
    items: TItem[];
    gap: number;
    render: (item: TItem) => ReactNode;
};

const Wrapper = styled.div<{ gap: number }>`
    display: flex;
    flex-direction: column;
    gap: ${({ gap }) => gap}px;
`;

export const ItemList = ({ items, render, gap }: TProps) => {
    return <Wrapper gap={gap}>{items.map(item => render(item))}</Wrapper>;
};
