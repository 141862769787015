import { styled } from '@shared/ui/theme';

type TProps = {
    progress: number;
};

const Wrapper = styled.div`
    height: 6px;
    position: relative;
    border-radius: 64px;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.surface.primary};
`;

const Progress = styled.div`
    min-width: 0;
    max-width: 100%;
    height: 6px;
    top: 0;
    left: 0;
    border-radius: 64px;
    position: absolute;
    background: linear-gradient(79deg, #ffa592 6.74%, #ff92a5 90.56%);
    transition: width 0.5s;
`;

export const ProgressBar = ({ progress }: TProps) => {
    return (
        <Wrapper>
            <Progress style={{ width: `${progress}%` }} />
        </Wrapper>
    );
};
