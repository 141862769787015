import { useNavigate } from 'react-router-dom';

import { paths } from '@shared/paths';
import { HeaderConnector } from '@widgets/header';

import { Suggestions } from './suggestions';

export const SuggestionsConnector = () => {
    const navigate = useNavigate();

    return (
        <Suggestions
            HeaderComponent={
                <HeaderConnector
                    isReturnable={false}
                    isSkippable={false}
                    isTransparent
                />
            }
            onContinue={() => navigate(paths.email)}
        />
    );
};
