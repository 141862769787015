import { Typography } from '@shared/ui/typography';

export const mapTitle = (progress: number) => {
    if (progress < 24) {
        return (
            <Typography variant="title3" fontWeight={300}>
                Analyzing your answers...
            </Typography>
        );
    }
    if (progress < 49) {
        return (
            <Typography variant="title3" fontWeight={300}>
                Generating personalized recommendation...
            </Typography>
        );
    }
    if (progress < 74) {
        return (
            <Typography variant="title3" fontWeight={300}>
                Predicting your next period...
            </Typography>
        );
    }
    return (
        <Typography variant="title1" color="#FF9494">
            Done!
        </Typography>
    );
};
