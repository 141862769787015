import { ReactNode } from 'react';

import {
    AppIcon,
    BarsHightIcon,
    BarsLowIcon,
    BarsMediumIcon,
    BathIcon,
    Blood1Icon,
    Blood2Icon,
    Blood3Icon,
    CalendarEventIcon,
    CalendarHeartIcon,
    CheckIcon,
    CrossIcon,
    GrowIcon,
    ListAllIcon,
    MailIcon,
    MonitoringIcon,
    PillsIcon,
    StarMichlenIcon,
    SunnyIcon,
} from '@shared/ui/icon';

export type TIconCode =
    | 'BARS_HIGHT'
    | 'BARS_MEDIUM'
    | 'CROSS'
    | 'CALENDAR_HEART'
    | 'APP'
    | 'STAR'
    | 'CALENDAR_EVENT'
    | 'MONITORING'
    | 'LIST_ALL'
    | 'BLOOD_1'
    | 'BLOOD_2'
    | 'BLOOD_3'
    | 'PILLS'
    | 'GROW'
    | 'BATH'
    | 'SUNNY'
    | 'CHECK'
    | 'BARS_LOW'
    | 'BARS_MEDIUM'
    | 'BARS_HIGHT'
    | 'MAIL';

export const mapIcon = (iconCode: TIconCode): ReactNode => {
    switch (iconCode) {
        case 'APP':
            return <AppIcon />;
        case 'BARS_HIGHT':
            return <BarsHightIcon />;
        case 'BARS_LOW':
            return <BarsLowIcon />;
        case 'BARS_MEDIUM':
            return <BarsMediumIcon />;
        case 'BATH':
            return <BathIcon />;
        case 'BLOOD_1':
            return <Blood1Icon />;
        case 'BLOOD_2':
            return <Blood2Icon />;
        case 'BLOOD_3':
            return <Blood3Icon />;
        case 'CALENDAR_EVENT':
            return <CalendarEventIcon />;
        case 'CALENDAR_HEART':
            return <CalendarHeartIcon />;
        case 'CHECK':
            return <CheckIcon />;
        case 'CROSS':
            return <CrossIcon />;
        case 'STAR':
            return <StarMichlenIcon />;
        case 'MONITORING':
            return <MonitoringIcon />;
        case 'LIST_ALL':
            return <ListAllIcon />;
        case 'PILLS':
            return <PillsIcon />;
        case 'GROW':
            return <GrowIcon />;
        case 'SUNNY':
            return <SunnyIcon />;
        case 'MAIL':
            return <MailIcon />;
        default:
            return <></>;
    }
};
