import { Button } from '@shared/ui/atoms/button';
import { IconChip } from '@shared/ui/atoms/icon-chip';
import { LockIcon } from '@shared/ui/icon';
import { styled, useTheme } from '@shared/ui/theme';
import { Typography } from '@shared/ui/typography';

import { MethodList } from '../method-list';
import { PlanCard } from '../plan-card';
import { PlanMask } from '../plan-mask';

type TProps = {
    variant: 'light' | 'dark';
    selectedPlan: string;
    onChangePlan: (planId: string) => void;
    onSubmit: () => void;
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const PlansWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

const DescriptionWrapper = styled.div<{ variant: 'light' | 'dark' }>`
    padding: 12px 16px;
    background-color: ${({ theme, variant }) =>
        variant === 'light'
            ? theme.colors.surface.secondary
            : 'rgba(255, 255, 255, 0.05)'};
    text-align: center;
    border-radius: 16px;
`;

const ChipWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
`;

const PaymentWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
`;

const ButtonWhiteWrapper = styled.div`
    border-radius: 24px;
    background-color: ${({ theme }) => theme.colors.background.primary};
`;

const plans = {
    1: {
        title: '1 month',
        integerPrice: 0,
        floatPrice: 33,
        initialPrice: 19.99,
        price: 9.99,
    },
    2: {
        title: '6 month',
        integerPrice: 0,
        floatPrice: 21,
        initialPrice: 59.99,
        price: 39.99,
    },
    3: {
        title: '12 month',
        integerPrice: 0,
        floatPrice: 14,
        initialPrice: 99.99,
        price: 49.99,
    },
};

export const Plan = ({
    variant,
    selectedPlan,
    onChangePlan,
    onSubmit,
}: TProps) => {
    const theme = useTheme();
    return (
        <Wrapper>
            <Typography
                color={
                    variant === 'light'
                        ? theme.colors.text.primary
                        : theme.colors.text.brand
                }
                variant="title1">
                Choose your plan
            </Typography>
            <PlansWrapper>
                <PlanCard
                    {...plans[1]}
                    isSelected={selectedPlan === '1'}
                    variant={variant}
                    currency="$"
                    onClick={() => onChangePlan('1')}
                />
                <PlanCard
                    {...plans[2]}
                    isSelected={selectedPlan === '2'}
                    variant={variant}
                    currency="$"
                    onClick={() => onChangePlan('2')}
                />
                <PlanMask variant={variant} isSelected={selectedPlan === '3'}>
                    <PlanCard
                        {...plans[3]}
                        isSelected={selectedPlan === '3'}
                        variant={variant}
                        currency="$"
                        onClick={() => onChangePlan('3')}
                    />
                </PlanMask>
            </PlansWrapper>

            <DescriptionWrapper variant={variant}>
                <Typography
                    variant="footnote"
                    display="block"
                    color={
                        variant === 'light'
                            ? theme.colors.text.secondary
                            : 'rgba(255, 255, 255, 0.50)'
                    }>
                    We've automatically applied discount to your first
                    subscription price. Please note that your subscription will
                    be automatically renewed at full price of $
                    {
                        plans[Number(selectedPlan) as keyof typeof plans]
                            .initialPrice
                    }{' '}
                    at the end of chosen subscription term.
                </Typography>
            </DescriptionWrapper>
            <ButtonWhiteWrapper>
                <Button onClick={onSubmit} variant="pulsar" fullWidth>
                    GET MY PLAN
                </Button>
            </ButtonWhiteWrapper>

            <ChipWrapper>
                <IconChip
                    variant={variant}
                    icon={<LockIcon />}
                    text="Secure payment"
                />
            </ChipWrapper>
            <PaymentWrapper>
                <MethodList variant={variant} />
            </PaymentWrapper>
        </Wrapper>
    );
};
