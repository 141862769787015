import { zodResolver } from '@hookform/resolvers/zod';
import { ReactNode, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';

import { setAnswer } from '@entities/questions/model/questions-domain';
import { styled } from '@shared/ui/theme';

type TProps = {
    schemaType: 'integer' | 'date' | null;
    stageNumber: number;
    children: ReactNode;
    nextStage: () => void;
};

const FormWrapper = styled.form`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 16px;
`;

type TDate = {
    date: string;
};

type TInteger = {
    integer: string;
};

const dateSchema = z.object({
    date: z
        .string({
            required_error: 'Date must be MM/DD/YYYY',
            invalid_type_error: 'Date must be MM/DD/YYYY',
        })
        .refine(
            str => /^\d{2}\/\d{2}\/\d{4}$/.test(str),
            'Date must be MM/DD/YYYY',
        ),
});

const integerSchema = z.object({
    integer: z.coerce
        .number({
            required_error:
                'On average, the maximum cycle can last from 15 to 50 days',
            invalid_type_error:
                'On average, the maximum cycle can last from 15 to 50 days',
        })
        .min(15, 'On average, the maximum cycle can last from 15 to 50 days')
        .max(50, 'On average, the maximum cycle can last from 15 to 50 days')
        .int('must be integer'),
});

const schemes = {
    integer: integerSchema,
    date: dateSchema,
};

export const ControlProvider = ({
    schemaType,
    stageNumber,
    children,
    nextStage,
}: TProps) => {
    const methods = useForm<TDate | TInteger>({
        mode: 'onChange',
        resolver: zodResolver(schemaType ? schemes[schemaType] : integerSchema),
        defaultValues: {
            integer: '28',
        },
    });
    const submitForm = useCallback(
        (values: { [key: string]: string | undefined }) => {
            const value = values?.date || values?.integer || '';
            setAnswer({
                [stageNumber]: value,
            });
            nextStage();
        },
        [nextStage, stageNumber],
    );
    if (!schemaType) {
        return <>{children}</>;
    }
    return (
        <FormProvider {...methods}>
            <FormWrapper onSubmit={methods.handleSubmit(submitForm)}>
                {children}
            </FormWrapper>
        </FormProvider>
    );
};
