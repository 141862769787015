export const LockIcon = () => {
    return (
        <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g id="lock">
                <path
                    id="Icon"
                    d="M8.49935 10.1667V11.5M5.16602 7.18586C5.4803 7.16667 5.86774 7.16667 6.36602 7.16667H10.6327C11.131 7.16667 11.5184 7.16667 11.8327 7.18586M5.16602 7.18586C4.7738 7.2098 4.49554 7.26364 4.25803 7.38465C3.88171 7.5764 3.57575 7.88236 3.384 8.25869C3.16602 8.68651 3.16602 9.24656 3.16602 10.3667V11.3C3.16602 12.4201 3.16602 12.9802 3.384 13.408C3.57575 13.7843 3.88171 14.0903 4.25803 14.282C4.68586 14.5 5.24591 14.5 6.36602 14.5H10.6327C11.7528 14.5 12.3128 14.5 12.7407 14.282C13.117 14.0903 13.4229 13.7843 13.6147 13.408C13.8327 12.9802 13.8327 12.4201 13.8327 11.3V10.3667C13.8327 9.24656 13.8327 8.68651 13.6147 8.25869C13.4229 7.88236 13.117 7.5764 12.7407 7.38465C12.5032 7.26364 12.2249 7.2098 11.8327 7.18586M5.16602 7.18586V5.83333C5.16602 3.99238 6.6584 2.5 8.49935 2.5C10.3403 2.5 11.8327 3.99238 11.8327 5.83333V7.18586"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    );
};
