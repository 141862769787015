import { SnackbarProvider } from 'notistack';
import { ReactNode } from 'react';
import { HelmetProvider } from 'react-helmet-async';

import { CurrentThemeProvider, GlobalStyles } from '@shared/ui/theme';

type Props = {
    children: ReactNode;
};
export const AppProviders = ({ children }: Props) => {
    return (
        <SnackbarProvider
            preventDuplicate={true}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            autoHideDuration={3000}>
            <CurrentThemeProvider>
                <GlobalStyles />
                <HelmetProvider>
                    <div id="modal-root">{children}</div>
                </HelmetProvider>
            </CurrentThemeProvider>
        </SnackbarProvider>
    );
};
