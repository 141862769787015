import { ReactNode } from 'react';

import { styled, useTheme } from '@shared/ui/theme';
import { breakpoints } from '@shared/ui/theme/dimensions';
import { Typography } from '@shared/ui/typography';

type Props = {
    HeaderComponent: ReactNode;
    questionNum: number;
    title: string;
    Control: ReactNode;
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    height: calc(100% - 76px);
    margin-top: 76px; //TODO to header-height const
`;

const HeaderWrapper = styled.div`
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
`;

const QuestionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 0 16px;
    @media (min-width: ${breakpoints.tablet}px) {
        text-align: center;
    }
`;

export const QuizTemplate = ({
    HeaderComponent,
    questionNum,
    title,
    Control,
}: Props) => {
    const theme = useTheme();
    return (
        <Wrapper>
            <HeaderWrapper>{HeaderComponent}</HeaderWrapper>
            <QuestionWrapper>
                <Typography
                    color={theme.colors.text.secondary}
                    variant="caption1">
                    Question {questionNum}
                </Typography>
                <Typography variant="title1">{title}</Typography>
            </QuestionWrapper>
            {Control}
        </Wrapper>
    );
};
