import styledComponents from 'styled-components';
import { ThemedBaseStyledInterface } from 'styled-components';

import { Theme } from './theme';

export { theme } from './theme';
export type { Theme } from './theme';
export { useTheme } from './use-theme';
export const styled: ThemedBaseStyledInterface<Theme> = styledComponents;
export { css } from 'styled-components';

export { GlobalStyles } from './global-styles';
export * from './constants';

export { CurrentThemeProvider } from './provider';

export { isValidTheme } from './utils';
