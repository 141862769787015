import { ReactNode, forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { SpaceProps, TextAlignProps, space, textAlign } from 'styled-system';

import { TypographyVariant } from '../types';
import * as Variants from '../typography-variants/typography-variants';

type TProps = SpaceProps & TextAlignProps;

type TFontWeight = 300 | 400 | 500 | 600;

type StyledTextProps = {
    $maxLines?: number | string;
    $display?: TextDisplay;
    $fontWeight?: TFontWeight;
    color?: string;
    whiteSpace?: string;
} & TProps;

const textStyles = css<StyledTextProps>`
    ${space}
    ${textAlign}
    ${({ $fontWeight }) => $fontWeight && `font-weight: ${$fontWeight};`}
    color: ${({ color }) => color || 'inherit'};
    ${({ whiteSpace }) => whiteSpace && `white-space: ${whiteSpace};`}
    ${({ $display }) => $display && `display: ${$display};`}
    ${({ $maxLines }) =>
        $maxLines &&
        `
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: ${$maxLines};
    overflow: hidden;
  `}
`;

const SpacingTitle1 = styled(Variants.Title1)<StyledTextProps>`
    ${textStyles}
`;
const SpacingTitle3 = styled(Variants.Title3)<StyledTextProps>`
    ${textStyles}
`;

const SpacingBody1 = styled(Variants.Body1)<StyledTextProps>`
    ${textStyles}
`;

const SpacingButton1 = styled(Variants.Button1)<StyledTextProps>`
    ${textStyles}
`;

const SpacingButton2 = styled(Variants.Button2)<StyledTextProps>`
    ${textStyles}
`;

const SpacingCaption1 = styled(Variants.Caption1)<StyledTextProps>`
    ${textStyles}
`;

const SpacingFootnote = styled(Variants.Footnote)<StyledTextProps>`
    ${textStyles}
`;

type TextDisplay = 'block' | 'inline-block';

// Используем только SpacingTitle1, так как вся типография
// одинакова по своей сути
type TextComponent = typeof SpacingTitle1;

const variantMap: Record<TypographyVariant, TextComponent> = {
    caption1: SpacingCaption1,
    title1: SpacingTitle1,
    title3: SpacingTitle3,
    body1: SpacingBody1,
    button1: SpacingButton1,
    button2: SpacingButton2,
    footnote: SpacingFootnote,
};

type Props = {
    /** **Важно**: при заданном `maxLines` всегда будет `display: -webkit-box` */
    display?: TextDisplay;
    variant?: TypographyVariant;
    maxLines?: number | string;
    children?: ReactNode;
    color?: string;
    whiteSpace?: string;
    fontWeight?: TFontWeight;
    style?: React.CSSProperties;
} & TProps;

export const Typography = forwardRef<HTMLSpanElement, Props>(
    (
        {
            variant = 'body1',
            children,
            maxLines,
            display,
            whiteSpace,
            fontWeight,
            ...rest
        },
        ref,
    ) => {
        const Text = variantMap[variant];
        return (
            <Text
                $maxLines={maxLines}
                $display={display}
                $fontWeight={fontWeight}
                whiteSpace={whiteSpace}
                ref={ref}
                {...rest}>
                {children}
            </Text>
        );
    },
);
