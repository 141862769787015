import { RoundCheck } from '@shared/ui/icon';
import { styled, useTheme } from '@shared/ui/theme';
import { Typography } from '@shared/ui/typography';

type TProps = {
    title: string;
    description: string;
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 12px;
    width: 100%;
`;

const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
`;

const IconWrapper = styled.div`
    margin-top: 3px;
    min-width: 17px;
    height: 17px;
`;

export const CheckedItem = ({ title, description }: TProps) => {
    const theme = useTheme();
    return (
        <Wrapper>
            <IconWrapper>
                <RoundCheck />
            </IconWrapper>
            <InfoWrapper>
                <Typography variant="title3">{title}</Typography>
                <Typography variant="body1" color={theme.colors.text.secondary}>
                    {description}
                </Typography>
            </InfoWrapper>
        </Wrapper>
    );
};
