import {
    ReactNode,
    createContext,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { ThemeProvider } from 'styled-components';

import { colors, theme } from './theme';
import type { TColors, ThemeType } from './types';
import { isValidTheme } from './utils';

export type ThemeContextValue = {
    setThemeType: (type: ThemeType | undefined) => void;
};

export const ThemeContext = createContext<ThemeContextValue>({
    setThemeType: () => undefined,
});

const THEME_STORAGE_KEY = 'appTheme';

type TCurrentThemeProviderProps = {
    defaultTheme?: ThemeType;
    children: ReactNode;
};

export const CurrentThemeProvider = ({
    defaultTheme,
    children,
}: TCurrentThemeProviderProps) => {
    let initialTheme: ThemeType | undefined;

    const [type, setType] = useState<ThemeType | undefined>(initialTheme);

    const contextValue = useMemo<ThemeContextValue>(
        () => ({
            setThemeType: (t?: ThemeType) => {
                setType(t);
            },
        }),
        [setType],
    );

    const color = useMemo<TColors>(() => {
        return { ...colors[defaultTheme || type || 'light'] };
    }, [defaultTheme, type]);

    const currentTheme = theme(color);

    return (
        <ThemeContext.Provider value={contextValue}>
            <ThemeProvider theme={currentTheme}>{children}</ThemeProvider>
        </ThemeContext.Provider>
    );
};

export function useThemeSwitcher(permanentTheme?: ThemeType) {
    const { setThemeType } = useContext(ThemeContext);

    useEffect(() => {
        const storageTheme = localStorage.getItem(THEME_STORAGE_KEY); //TODO set this on change theme

        if (permanentTheme) {
            setThemeType(permanentTheme);
            return;
        }

        if (storageTheme && isValidTheme(storageTheme)) {
            setThemeType(storageTheme);
            return;
        }
    }, [permanentTheme, setThemeType]);
    return { setThemeType };
}
