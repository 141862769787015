export const PillsIcon = () => {
    return (
        <svg
            height="20"
            width="20"
            fill="none"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6 0C4.4087 0 2.88258 0.632141 1.75736 1.75736C0.632141 2.88258 0 4.4087 0 6C0 7.5913 0.632141 9.11742 1.75736 10.2426C2.88258 11.3679 4.4087 12 6 12C7.5913 12 9.11742 11.3679 10.2426 10.2426C11.3679 9.11742 12 7.5913 12 6C12 4.4087 11.3679 2.88258 10.2426 1.75736C9.11742 0.632141 7.5913 0 6 0ZM3.96849 2.55427C4.57885 2.19444 5.2794 2 6 2C7.06087 2 8.07828 2.42143 8.82843 3.17157C9.57857 3.92172 10 4.93913 10 6C10 6.7206 9.80556 7.42115 9.44573 8.03151L3.96849 2.55427ZM2.55427 3.96849C2.19444 4.57885 2 5.2794 2 6C2 7.06087 2.42143 8.07828 3.17157 8.82843C3.92172 9.57857 4.93913 10 6 10C6.7206 10 7.42115 9.80556 8.03151 9.44573L2.55427 3.96849Z"
                fill="currentColor"
                fillRule="evenodd"
            />
            <path
                d="M15 10C13.6739 10 12.4021 10.5268 11.4645 11.4645C10.5268 12.4021 10 13.6739 10 15C10 16.3261 10.5268 17.5979 11.4645 18.5355C12.4021 19.4732 13.6739 20 15 20C16.3261 20 17.5979 19.4732 18.5355 18.5355C19.4732 17.5979 20 16.3261 20 15C20 13.6739 19.4732 12.4021 18.5355 11.4645C17.5979 10.5268 16.3261 10 15 10ZM12.8787 12.8787C13.4413 12.3161 14.2044 12 15 12C15.4517 12 15.8929 12.1019 16.2929 12.2929L12.2929 16.2929C12.1019 15.8929 12 15.4517 12 15C12 14.2044 12.3161 13.4413 12.8787 12.8787ZM13.7071 17.7071C14.1071 17.8981 14.5483 18 15 18C15.7956 18 16.5587 17.6839 17.1213 17.1213C17.6839 16.5587 18 15.7956 18 15C18 14.5483 17.8981 14.1071 17.7071 13.7071L13.7071 17.7071Z"
                fill="currentColor"
                fillRule="evenodd"
            />
        </svg>
    );
};
