import { Helmet } from 'react-helmet-async';

type Props = {
    children: string;
};

export const MetaTitle = ({ children }: Props) => {
    return (
        <Helmet>
            <title>{children}</title>
        </Helmet>
    );
};
