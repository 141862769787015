import { useEffect } from 'react';

type TProps = {
    blockRef: React.MutableRefObject<HTMLDivElement | null>;
    startBlockRef: React.MutableRefObject<HTMLDivElement | null>;
    endBlockRef: React.MutableRefObject<HTMLDivElement | null>;
    children: React.ReactNode;
    onEnter: () => void;
    onExit: () => void;
};

export const ScrollListener = ({
    blockRef,
    startBlockRef,
    endBlockRef,
    children,
    onEnter,
    onExit,
}: TProps) => {
    const listenToScroll = () => {
        if (
            !startBlockRef.current ||
            !endBlockRef.current ||
            !blockRef.current
        ) {
            return;
        }
        if (
            blockRef.current.offsetTop > startBlockRef.current.offsetTop &&
            blockRef.current.offsetTop < endBlockRef.current.offsetTop - 200
        ) {
            onEnter();
            return;
        }
        if (
            blockRef.current.offsetTop <
                startBlockRef.current.offsetTop - 100 ||
            blockRef.current.offsetTop > endBlockRef.current.offsetTop - 200
        ) {
            onExit();
        }
    };
    useEffect(() => {
        window.addEventListener('scroll', listenToScroll);
        return () => window.removeEventListener('scroll', listenToScroll);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <>{children}</>;
};
