import { RouteObject } from 'react-router-dom';

import { paths } from '@shared/paths';
import { MetaTitle } from '@shared/ui/atoms/meta-title';

import { PaywallPageConnector } from './paywall-page-connector';

export const routes: RouteObject[] = [
    {
        path: paths.paywall,
        element: (
            <>
                <MetaTitle>Mooni</MetaTitle>
                <PaywallPageConnector />
            </>
        ),
    },
];
