import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import { AppProviders } from './app-providers';
import { routes } from './routes';

import 'react-day-picker/dist/style.css';

const router = createBrowserRouter(routes);

export const App = () => {
    return (
        <AppProviders>
            <RouterProvider router={router} />
        </AppProviders>
    );
};
