import { ButtonHTMLAttributes, ReactNode } from 'react';

import { css, styled } from '@shared/ui/theme';
import { breakpoints } from '@shared/ui/theme/dimensions';

type TProps = {
    children: ReactNode;
    fullWidth?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const ICON_SIZE = 24;

const ButtonColorsMixin = css`
    color: ${({ theme }) => theme.buttons.icon.enabled.color};
    background: ${({ theme }) => theme.buttons.icon.enabled.backgroundColor};
    @media (min-width: ${breakpoints.tablet}px) {
        &:hover {
            color: ${({ theme }) => theme.buttons.icon.pressed.color};
            background: ${({ theme }) =>
                theme.buttons.icon.pressed.backgroundColor};
        }
    }

    &:active {
        color: ${({ theme }) => theme.buttons.icon.pressed.color};
        background: ${({ theme }) =>
            theme.buttons.icon.pressed.backgroundColor};
    }

    &:disabled {
        color: ${({ theme }) => theme.buttons.icon.disabled.color};
        background: ${({ theme }) =>
            theme.buttons.icon.disabled.backgroundColor};
    }
`;

const ButtonWrapper = styled.button<{
    fullWidth?: boolean;
}>`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 12px 16px;
    border-radius: 14px;
    cursor: pointer;
    outline: none;
    width: ${({ fullWidth }) => (fullWidth ? '100%' : 'fit-content')};
    ${ButtonColorsMixin}

    &:disabled {
        pointer-events: none;
    }
`;

const IconWrapper = styled.div<{ disabled?: boolean }>`
    width: ${ICON_SIZE}px;
    height: ${ICON_SIZE}px;
    color: inherit;
`;

export const IconButton = ({ children, fullWidth, ...rest }: TProps) => {
    return (
        <ButtonWrapper fullWidth={fullWidth} {...rest}>
            <IconWrapper>{children}</IconWrapper>
        </ButtonWrapper>
    );
};
