import { ButtonHTMLAttributes, ReactNode } from 'react';

import { css, styled } from '@shared/ui/theme';
import { Typography } from '@shared/ui/typography';

type TProps = {
    variant?: TButtonVariant;
    children: ReactNode;
    fullWidth?: boolean;
    startIcon?: ReactNode;
    endIcon?: ReactNode;
} & ButtonHTMLAttributes<HTMLButtonElement>;

type TButtonVariant = 'contained' | 'text' | 'pulsar';

const ICON_SIZE = 24;

const PulsarMixin = css`
    @keyframes pulsar {
        from {
            -webkit-mask-position: 150%;
        }
        to {
            -webkit-mask-position: -50%;
        }
    }
    mask-image: linear-gradient(
        -75deg,
        rgba(0, 0, 0, 1) 30%,
        rgba(0, 0, 0, 0.75) 50%,
        rgba(0, 0, 0, 1) 70%
    );
    mask-size: 200%;
    animation: pulsar 2s linear infinite;
`;

const ButtonColorsMixin = css<{ variant: TButtonVariant }>`
    color: ${({ theme, variant }) => theme.buttons[variant].enabled.color};
    background: ${({ theme, variant }) =>
        theme.buttons[variant].enabled.backgroundColor};
    &:hover {
        color: ${({ theme, variant }) => theme.buttons[variant].pressed.color};
        background: ${({ theme, variant }) =>
            theme.buttons[variant].pressed.backgroundColor};
    }

    &:active {
        color: ${({ theme, variant }) => theme.buttons[variant].pressed.color};
        background: ${({ theme, variant }) =>
            theme.buttons[variant].pressed.backgroundColor};
    }

    &:disabled {
        color: ${({ theme, variant }) => theme.buttons[variant].disabled.color};
        background: ${({ theme, variant }) =>
            theme.buttons[variant].disabled.backgroundColor};
    }
`;

const ButtonWrapper = styled.button<{
    variant: TButtonVariant;
    fullWidth?: boolean;
}>`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 12px 16px;
    border-radius: ${({ variant }) => (variant === 'pulsar' ? '24px' : '14px')};
    cursor: pointer;
    outline: none;
    width: ${({ fullWidth }) => (fullWidth ? '100%' : 'fit-content')};
    ${ButtonColorsMixin}
    ${({ variant }) => (variant === 'pulsar' ? PulsarMixin : '')}
    &:disabled {
        pointer-events: none;
    }
`;

const IconWrapper = styled.div<{ disabled?: boolean }>`
    width: ${ICON_SIZE}px;
    height: ${ICON_SIZE}px;
    color: inherit;
`;

export const Button = ({
    variant = 'contained',
    children,
    fullWidth,
    startIcon,
    endIcon,
    ...rest
}: TProps) => {
    return (
        <ButtonWrapper variant={variant} fullWidth={fullWidth} {...rest}>
            {startIcon && <IconWrapper>{startIcon}</IconWrapper>}
            <Typography variant="button1">{children}</Typography>
            {endIcon && <IconWrapper>{endIcon}</IconWrapper>}
        </ButtonWrapper>
    );
};
