import { styled } from '@shared/ui/theme';
import { Typography } from '@shared/ui/typography';

type Props = {
    variant: 'dark' | 'light';
};

const PRIVACY_LINK = 'http://mooni.health.tilda.ws/privacy';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: space-between;
    align-items: center;
    min-height: 56px;
    flex-wrap: wrap;
`;

const PointerWrapper = styled.div`
    cursor: pointer;
`;

const LinksWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
`;

export const Footer = ({ variant }: Props) => {
    const typoColor = variant === 'light' ? '#fff' : '#000';

    const PointerLink = ({ title, link }: { title: string; link: string }) => (
        <PointerWrapper style={{ color: typoColor }}>
            <a href={link}>
                <Typography variant="footnote">{title}</Typography>
            </a>
        </PointerWrapper>
    );
    return (
        <Wrapper>
            <Typography variant="caption1" color={typoColor}>
                © 2024 All Right Reserved | MOONI
            </Typography>
            <LinksWrapper>
                <PointerLink title="Privacy Policy" link={PRIVACY_LINK} />
            </LinksWrapper>
        </Wrapper>
    );
};
