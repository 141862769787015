import { useStore } from 'effector-react';
import { useNavigate } from 'react-router-dom';

import { $answers } from '@entities/questions/model/questions-domain';
import { paths } from '@shared/paths';
import { HeaderConnector } from '@widgets/header';

import { ResultPage } from './result-page';
import { mapRecommendations } from './utils/mappers/recommendations-mapper';

export const ResultPageConnector = () => {
    const navigate = useNavigate();
    const answers = useStore($answers);
    const recommendations = answers
        ? mapRecommendations({ 4: answers[4], 6: answers[6], 14: answers[14] })
        : [];
    return (
        <ResultPage
            HeaderComponent={
                <HeaderConnector
                    isReturnable={false}
                    isSkippable={false}
                    isTransparent={false}
                />
            }
            onContinue={() => navigate(paths.paywall)}
            recommendations={recommendations}
        />
    );
};
