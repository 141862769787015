type TAnswers = {
    4: number | string;
    6: number | string;
    14: number | string;
};

type TAnswersSchema = {
    [key: number]: { [key: number | string]: string | undefined };
};

const answersSchema: TAnswersSchema = {
    4: {
        0: 'Consider supplementing with iron, as lighter menstrual flow may result in lower iron loss. Iron is essential for preventing iron-deficiency anemia. Include iron-rich foods in your diet such as spinach, lentils, fortified cereals, and lean meats.',
        1: 'Focus on a balanced diet that includes iron-rich foods. If your diet is insufficient, consider a multivitamin that includes iron to support overall health. Include sources of iron such as beans, tofu, poultry, and whole grains in your meals.',
        2: 'In addition to maintaining a diet rich in iron, consider omega-3 fatty acids and vitamin C. Omega-3 fatty acids may help with inflammation, and vitamin C can enhance iron absorption. Include foods like fatty fish (salmon, mackerel), flaxseeds, chia seeds for omega-3, and citrus fruits, strawberries, and bell peppers for vitamin C in your diet.',
    },
    6: {
        0: 'Adequate water intake is essential for overall health and can contribute to hormonal balance. Aim to drink at least 8 glasses (approximately 2 liters) of water daily to support your well-being and menstrual health.',
    },
    14: {
        0: `Continue meticulous tracking of your menstrual cycles along with lifestyle factors such as exercise routines, dietary habits, and stress levels. Consistent monitoring will help you maintain the positive pattern you've observed.`,
        1: 'Enhance your tracking efforts by closely monitoring both the timing of your menstrual cycles and lifestyle factors like exercise intensity, dietary changes, and stress levels. Look for correlations to better understand the variations.',
        2: 'Prioritize detailed tracking of both menstrual cycles and lifestyle factors. Document variations in exercise, diet, and stress to identify potential triggers for irregularities. Share this comprehensive information with a healthcare professional for personalized assessment and guidance.',
    },
};

export const mapRecommendations = (answers: TAnswers): string[] => {
    const recommendations: string[] = [];
    const answer4Text = answersSchema[4][answers[4]];
    const answer6Text = answersSchema[6][answers[6]];
    const answer14Text = answersSchema[14][answers[14]];
    if (answer4Text) {
        recommendations.push(answer4Text);
    }
    if (answer6Text) {
        recommendations.push(answer6Text);
    }
    if (answer14Text) {
        recommendations.push(answer14Text);
    }
    return recommendations;
};
