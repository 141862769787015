import { styled } from '@shared/ui/theme';

import img from './img.webp';

const Wrapper = styled.div`
    margin-top: 62px;
`;

const ImgWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
`;

export const Research = () => {
    return (
        <Wrapper>
            <ImgWrapper>
                <img src={img} alt="" width="242px" height="242px" />
            </ImgWrapper>
        </Wrapper>
    );
};
