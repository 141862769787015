import { ReactNode, useEffect, useState } from 'react';
import {
    CircularProgressbarWithChildren,
    buildStyles,
} from 'react-circular-progressbar';

import { Button } from '@shared/ui/atoms/button';
import { CheckIcon } from '@shared/ui/icon';
import 'react-circular-progressbar/dist/styles.css';
import { CheckedText } from '@shared/ui/molecules/checked-text';
import { ControlPageTemplate } from '@shared/ui/templates/control-page-template';
import { styled, useTheme } from '@shared/ui/theme';
import { Typography } from '@shared/ui/typography';

import circleBig from './assets/circle-big.webp';
import circleMedium from './assets/circle-medium.webp';
import { mapTitle } from './mappers/title-mapper';

type Props = {
    HeaderComponent: ReactNode;
    onContinue: () => void;
};

const ProgressWrapper = styled.div`
    z-index: 10;
    margin: 16px 16px 32px 16px;
    width: 247px;
    height: 247px;
`;

const InfoWrapper = styled.div`
    z-index: 10;
    padding-bottom: 16px;
    text-align: center;
    height: 74px;
`;

const StagesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    gap: 16px;
`;

const Circle1Wrapper = styled.div`
    position: absolute;
    top: 25%;
    left: 80%;
    @keyframes vertical {
        from {
            top: 35%; /* от: left: 0px */
        }
        to {
            top: 15%; /* до: left: 100%-50px */
        }
    }
    animation: vertical 5s infinite linear;
    animation-direction: alternate;
`;

const Circle2Wrapper = styled.div`
    position: absolute;
    top: 10%;
    left: 5%;
    @keyframes vertical2 {
        from {
            top: 10%; /* от: left: 0px */
        }
        to {
            top: 20%; /* до: left: 100%-50px */
        }
    }
    animation: vertical2 10s infinite linear;
    animation-direction: alternate;
`;

const ButtonWrapper = styled.div<{ isVisible: boolean }>`
    width: 100%;
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    transition: opacity 2s linear;
`;

export const Suggestions = ({ HeaderComponent, onContinue }: Props) => {
    const theme = useTheme();
    const [progress, setProgress] = useState<number>(0);
    useEffect(() => {
        const interval = setInterval(() => {
            setProgress(prev => {
                if (prev >= 100) {
                    clearInterval(interval);
                    return 100;
                }
                return prev + 1;
            });
        }, 70);
        return () => clearInterval(interval);
    }, []);
    return (
        <ControlPageTemplate
            HeaderComponent={HeaderComponent}
            ControlComponent={
                <ButtonWrapper isVisible={progress > 99}>
                    <Button onClick={onContinue} variant="contained" fullWidth>
                        Continue
                    </Button>
                </ButtonWrapper>
            }
            ContentComponent={
                <>
                    <ProgressWrapper>
                        <CircularProgressbarWithChildren
                            value={progress}
                            styles={buildStyles({
                                pathColor: theme.colors.text.brand,
                            })}>
                            {progress < 100 ? (
                                <Typography
                                    variant="title1"
                                    color={theme.colors.text.brand}>
                                    {progress}%
                                </Typography>
                            ) : (
                                <div style={{ color: theme.colors.text.brand }}>
                                    <CheckIcon size={128} />
                                </div>
                            )}
                        </CircularProgressbarWithChildren>
                    </ProgressWrapper>
                    <InfoWrapper>{mapTitle(progress)}</InfoWrapper>
                    <StagesWrapper>
                        <CheckedText
                            text="Analyzing your answers"
                            isVisible={progress > 24}
                        />

                        <CheckedText
                            text="Generating personalized recommendation"
                            isVisible={progress > 49}
                        />

                        <CheckedText
                            text="Predicting your next period"
                            isVisible={progress > 74}
                        />
                    </StagesWrapper>
                </>
            }>
            <Circle1Wrapper>
                <img src={circleMedium} alt="" />
            </Circle1Wrapper>
            <Circle2Wrapper>
                <img src={circleBig} alt="" />
            </Circle2Wrapper>
        </ControlPageTemplate>
    );
};
