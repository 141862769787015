import { ReactNode, useMemo } from 'react';

import { Button } from '@shared/ui/atoms/button';
import { Banner } from '@shared/ui/molecules/banner';
import { ControlPageTemplate } from '@shared/ui/templates/control-page-template';
import { styled, useTheme } from '@shared/ui/theme';
import { Typography } from '@shared/ui/typography';

import img from './assets/img.webp';

type Props = {
    HeaderComponent: ReactNode;
    recommendations: string[];
    onContinue: () => void;
};

const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
    gap: 8px;
`;

export const ResultPage = ({
    HeaderComponent,
    recommendations,
    onContinue,
}: Props) => {
    const theme = useTheme();
    const BannerDescription = useMemo(
        () =>
            recommendations.map(item => (
                <>
                    • {item} <br />
                </>
            )),
        [recommendations],
    );

    return (
        <ControlPageTemplate
            HeaderComponent={HeaderComponent}
            ContentComponent={
                <>
                    <img src={img} alt="" width="220px" height="220px" />
                    <InfoWrapper>
                        <Typography variant="title1">
                            Congratulations <br />
                            on completing your profile!
                        </Typography>
                        <Typography
                            variant="body1"
                            color={theme.colors.text.secondary}>
                            We have carefully analyzed all the information you
                            provided and prepared the following for you
                        </Typography>
                    </InfoWrapper>
                    {recommendations.length > 0 && (
                        <Banner
                            title="Recommendations for you:"
                            description={BannerDescription}
                        />
                    )}
                </>
            }
            ControlComponent={
                <Button onClick={onContinue} variant="contained" fullWidth>
                    Continue
                </Button>
            }
        />
    );
};
