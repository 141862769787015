export const StarMichlenIcon = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.792 17.063C14.792 17.4 14.849 17.681 14.849 17.963C14.849 19.763 13.611 21 11.867 21C10.067 21 8.88699 19.762 8.88699 17.794V17.063C7.92999 17.738 7.31099 17.963 6.46699 17.963C4.94899 17.963 3.54199 16.5 3.54199 14.869C3.54199 13.688 4.38599 12.675 5.62399 12.113L5.90399 12C4.32999 11.213 3.54199 10.312 3.54199 9.075C3.54199 7.388 4.83599 5.981 6.46699 5.981C7.14199 5.981 7.98699 6.319 8.60499 6.769L8.88599 6.881C8.88599 6.544 8.82999 6.262 8.82999 6.037C8.82999 4.237 10.067 3 11.81 3C13.61 3 14.791 4.237 14.791 6.206V6.6L14.735 6.881C15.691 6.206 16.31 5.981 17.154 5.981C18.673 5.981 20.079 7.444 20.079 9.075C20.079 10.256 19.235 11.269 17.998 11.831L17.716 12C19.291 12.787 20.079 13.688 20.079 14.925C20.079 16.613 18.785 18.019 17.154 18.019C16.479 18.019 15.579 17.738 15.016 17.231L14.791 17.062L14.792 17.063Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
