export const checkedText = [
    {
        title: 'Tailored Well-being Tips',
        description:
            'Unlock a trove of personalized advice designed to empower you in managing PMS symptoms and enhancing your overall well-being. Our insights are crafted with your specific needs in mind, providing guidance for a healthier and happier you.',
    },
    {
        title: 'Personalized Period Predictions',
        description: `Receive accurate forecasts for your upcoming menstrual cycles based on the comprehensive data you've shared. Our advanced algorithms analyze your unique patterns, offering predictions customized to your body's rhythm.`,
    },
    {
        title: 'Cycle Insights',
        description:
            'Dive deep into understanding the intricacies of your menstrual cycle. Gain valuable insights into patterns and trends, fostering a profound awareness of your body. Armed with this knowledge, you can make informed decisions for a healthier lifestyle.',
    },
    {
        title: 'Exclusive Recommendations',
        description:
            'Delve into a realm of exclusive tips meticulously curated just for you. Our special recommendations ensure that your experience is not only tailored but also unique, catering to your individual preferences and well-being goals.',
    },
];

export const answerText = [
    {
        title: 'What does the period tracking app do?',
        description: `Our app helps you track your menstrual cycle, predict your periods, and understand your ovulation phases. It's a comprehensive tool to manage and monitor your reproductive health.`,
    },
    {
        title: 'How accurate is the period prediction feature?',
        description: `The period prediction is based on the data you input about your menstrual cycle. The more consistent your tracking, the more accurate the predictions become over time.`,
    },
    {
        title: 'Can I track my fertility and ovulation using this app?',
        description:
            'The period prediction is based on the data you input about your menstrual cycle. The more consistent your tracking, the more accurate the predictions become over time.',
    },
    {
        title: 'How does the app handle irregular periods or changes in cycle length?',
        description:
            'Our app is designed to adapt to changes in your menstrual cycle. It provides insights into irregularities and helps you understand potential factors influencing changes in cycle length.',
    },
    {
        title: 'Can I track symptoms or moods related to my menstrual cycle?',
        description:
            'Yes, the app allows you to log and track various symptoms, moods, and activities. This feature provides a holistic view of your well-being throughout your cycle.',
    },
];

export const reviewText = [
    {
        title: 'Mooni is my go to period app!',
        description: `It predicts my cycles accurately and helps me understand my mood patterns. I've noticed fewer PMS symptoms, making planning around my cycle a breeze. Feel more connected to my body now!`,
        author: 'millie_parker',
    },
    {
        title: 'Super user-friendly',
        description: `Been using Mooni for a few months, and it's been a game-changer. Tracking lifestyle with my cycle revealed patterns. Adjusted my diet, added stress relief, and voila – more regular cycles! Super user-friendly with insightful charts`,
        author: '#m_and_m_anderson',
    },
    {
        title: 'Mooni is a life saver!',
        description: `I can't believe the difference it's made in understanding my cycle. It's not just about predicting periods; it's a whole wellness tracker. After using it consistently, I've noticed less cramping and a more predictable flow. This app is a must-have for anyone wanting to take control of their menstrual health`,
        author: '#livvy_turner',
    },
    {
        title: 'Highly recommend it!',
        description: `Mooni has truly transformed how I approach my menstrual health. It's not just an app; it's like having a supportive friend guiding you through each cycle. The insights into my body's patterns have allowed me to make simple adjustments, and I've noticed a positive shift in my overall well-being`,
        author: '#a_bennett',
    },
];
