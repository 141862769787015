import { styled } from '@shared/ui/theme';

import gif from './assets/confety.gif';
import img from './assets/img.webp';

const Wrapper = styled.div`
    margin-top: 15%;
`;

const ImgWrapper = styled.div`
    padding-top: 15%;
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
`;

const Confety = styled.div`
    z-index: 1;
    top: 0;
    background: url(${gif});
    background-position: center;
    background-size: 150% 100%;
    mix-blend-mode: darken;
    position: absolute;
    width: 100%;
    height: 100%;
`;

export const Congratulations = () => {
    return (
        <Wrapper>
            <ImgWrapper>
                <img
                    src={img}
                    alt=""
                    width="292px"
                    height="242px"
                    style={{ zIndex: 2 }}
                />
                <Confety />
            </ImgWrapper>
        </Wrapper>
    );
};
