import { createDomain, createStore, restore } from 'effector';
import { persist as persistSession } from 'effector-storage/session';

export const questionsDomain = createDomain('question');
export const resetQuestionsDomain = questionsDomain.createEvent();

questionsDomain.onCreateStore(store => {
    store.reset(questionsDomain);
});

export const setStage = questionsDomain.createEvent<number>('stage');
export const $stage = restore(setStage, 0);

persistSession({
    key: 'stage',
    store: $stage,
});

type TAnswers = {
    [key: number]: number | string;
};

export const setAnswer = questionsDomain.createEvent<TAnswers>('answer');
export const $answers = createStore<TAnswers | null>(null).on(
    setAnswer,
    (store, payload) => ({ ...store, ...payload }),
);

persistSession({
    key: 'answers',
    store: $answers,
});
