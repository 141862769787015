import { RouteObject } from 'react-router-dom';

import { paths } from '@shared/paths';
import { MetaTitle } from '@shared/ui/atoms/meta-title';
import { PageTemplate } from '@shared/ui/templates/page-template';

import { QuizConnector } from './quiz-connector';

export const routes: RouteObject[] = [
    {
        path: paths.quiz,
        element: (
            <PageTemplate>
                <MetaTitle>Mooni</MetaTitle>
                <QuizConnector />
            </PageTemplate>
        ),
    },
];
