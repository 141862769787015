import { ButtonHTMLAttributes, ReactNode } from 'react';

import { css, styled, useTheme } from '@shared/ui/theme';
import { breakpoints } from '@shared/ui/theme/dimensions';
import { Typography } from '@shared/ui/typography';

type TProps = {
    description?: string;
    isSelected?: boolean;
    children: ReactNode;
    fullWidth?: boolean;
    startIcon?: ReactNode;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const ICON_SIZE = 24;

const ButtonColorsMixin = css<{ isSelected?: boolean }>`
    color: ${({ theme, isSelected }) =>
        isSelected
            ? theme.radioButtons.pressed.color
            : theme.radioButtons.enabled.color};
    background: ${({ theme, isSelected }) =>
        isSelected
            ? theme.radioButtons.pressed.backgroundColor
            : theme.radioButtons.enabled.backgroundColor};
    &:active {
        color: ${({ theme }) => theme.radioButtons.pressed.color};
        background: ${({ theme }) =>
            theme.radioButtons.pressed.backgroundColor};
    }

    &:active svg {
        color: ${({ theme }) => theme.colors.icon.invertPrimary};
    }
    @media (min-width: ${breakpoints.tablet}px) {
        &:hover {
            color: ${({ theme }) => theme.radioButtons.pressed.color};
            background: ${({ theme }) =>
                theme.radioButtons.pressed.backgroundColor};
        }

        &:hover svg {
            color: ${({ theme }) => theme.colors.icon.invertPrimary};
        }
    }
`;

const ButtonWrapper = styled.button<{
    fullWidth?: boolean;
    isSelected?: boolean;
}>`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    padding: 12px 16px;
    border-radius: 14px;
    cursor: pointer;
    outline: none;
    width: ${({ fullWidth }) => (fullWidth ? '100%' : 'fit-content')};
    ${ButtonColorsMixin}

    &:disabled {
        pointer-events: none;
    }
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    text-align: start;
    gap: 2px;
`;

const IconWrapper = styled.div<{ isSelected?: boolean }>`
    width: ${ICON_SIZE}px;
    height: ${ICON_SIZE}px;
    color: ${({ theme, isSelected }) =>
        isSelected ? theme.colors.icon.invertPrimary : theme.colors.icon.brand};
`;

export const RadioButton = ({
    children,
    fullWidth,
    startIcon,
    description,
    isSelected,
    ...rest
}: TProps) => {
    const theme = useTheme();

    return (
        <ButtonWrapper isSelected={isSelected} fullWidth={fullWidth} {...rest}>
            <IconWrapper isSelected={isSelected}>{startIcon}</IconWrapper>
            <TextWrapper>
                <Typography variant="button1">{children}</Typography>
                <Typography
                    variant="caption1"
                    color={
                        isSelected
                            ? theme.colors.text.invertSecondary
                            : theme.colors.text.secondary
                    }>
                    {description}
                </Typography>
            </TextWrapper>
        </ButtonWrapper>
    );
};
