import { useNavigate } from 'react-router-dom';

import { paths } from '@shared/paths';
import { HeaderConnector } from '@widgets/header';

import { Home } from './home';

export const HomeConnector = () => {
    const navigate = useNavigate();
    return (
        <Home
            HeaderComponent={
                <HeaderConnector
                    isReturnable={false}
                    isSkippable={false}
                    isTransparent
                />
            }
            onContinue={() => navigate(paths.quiz)}
        />
    );
};
