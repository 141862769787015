import { ReactNode } from 'react';

import { Button } from '@shared/ui/atoms/button';
import { css, styled, useTheme } from '@shared/ui/theme';
import { breakpoints } from '@shared/ui/theme/dimensions';
import { Typography } from '@shared/ui/typography';

type Props = {
    HeaderComponent: ReactNode;
    HeroComponent: ReactNode;
    title: string;
    description: string;
    isContentBottom?: boolean;
    onContinue: () => void;
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    background: ${({ theme }) => theme.colors.background.coral};
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    max-width: 450px;
`;

const HeaderWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
`;

const InfoWrapper = styled.div`
    display: flex;
    z-index: 10;
    flex-direction: column;
    gap: 8px;
    padding: 0 16px;
    @media (min-width: ${breakpoints.tablet}px) {
        text-align: center;
    }
`;

const ControlWrapper = styled.div<{ isCard?: boolean }>`
    display: flex;
    flex-direction: column;
    z-index: 10;
    margin-bottom: 16px;
    padding: 0 16px;
    ${({ isCard, theme }) =>
        isCard
            ? css`
                  background-color: ${theme.colors.background.primary};
                  border-radius: 16px 16px 0 0;
                  gap: 24px;
                  padding-top: 24px;
              `
            : ''}
`;

export const InfoTemplate = ({
    HeaderComponent,
    HeroComponent,
    title,
    description,
    isContentBottom,
    onContinue,
}: Props) => {
    const theme = useTheme();
    return (
        <Wrapper>
            <HeaderWrapper>{HeaderComponent}</HeaderWrapper>
            <ContentWrapper>
                {HeroComponent}

                {!isContentBottom && (
                    <InfoWrapper>
                        <Typography variant="title1">{title}</Typography>
                        <Typography
                            color={theme.colors.text.secondary}
                            variant="body1">
                            {description}
                        </Typography>
                    </InfoWrapper>
                )}
            </ContentWrapper>
            <ControlWrapper isCard={isContentBottom}>
                {isContentBottom && (
                    <InfoWrapper>
                        <Typography variant="title1">{title}</Typography>
                        <Typography
                            color={theme.colors.text.secondary}
                            variant="body1">
                            {description}
                        </Typography>
                    </InfoWrapper>
                )}
                <Button fullWidth onClick={onContinue}>
                    Continue
                </Button>
            </ControlWrapper>
        </Wrapper>
    );
};
