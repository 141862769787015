import { ReactNode } from 'react';

import { TStage } from '@entities/questions/model/types';
import { mapHero } from '@entities/questions/model/utils/mappers';
import { InfoTemplate } from '@shared/ui/templates/info-template';
import { QuizTemplate } from '@shared/ui/templates/quiz-template';
import { HeaderConnector } from '@widgets/header';

type Props = {
    stage: TStage;
    currentStage: number;
    nextStage: () => void;
    Control: ReactNode;
};

export const Quiz = ({ stage, currentStage, nextStage, Control }: Props) => {
    const { type, info, isSkippable } = stage;
    if (type === 'INFO') {
        return (
            <InfoTemplate
                title={info.title}
                description={info.description}
                HeaderComponent={
                    <HeaderConnector
                        isReturnable={currentStage > 0}
                        isSkippable={isSkippable}
                        onSkip={nextStage}
                        isTransparent
                        isProgress
                    />
                }
                isContentBottom={info.isContentBottom}
                HeroComponent={mapHero(info.HeroComponent)}
                onContinue={nextStage}
            />
        );
    }
    if (type === 'QUESTION') {
        return (
            <QuizTemplate
                HeaderComponent={
                    <HeaderConnector
                        isReturnable={currentStage > 0}
                        isSkippable={isSkippable}
                        onSkip={nextStage}
                        isTransparent
                        isProgress
                    />
                }
                questionNum={info.number}
                title={info.text}
                Control={Control}
            />
        );
    }
    return null;
};
