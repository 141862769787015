import { createGlobalStyle } from 'styled-components';

export const DISABLE_SCROLL_CLASS = 'disable-scroll';
export const CSS_VAR_BODY_WIDTH = '--body-width';
export const CSS_VAR_WINDOW_WIDTH = '--window-width';

export const GlobalStyles = createGlobalStyle`
  :root {
    --g-font: 'Poppins';
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
  
  *, *::before, *::after {
    box-sizing: border-box;
    font-family: var(--g-font); 
  } 
  
  body {
    margin: 0 !important;
    padding: 0 !important;
    background: ${({ theme }) => theme.colors.background.primary};
  }
 
  h1, h2, h3, h4, h5, h6 {
    margin-block: 0;  
  }

  button {
    display: inline-block;
    border: none;
    padding: 0;
    margin: 0;
    text-decoration: none;
    font-family: sans-serif;
    font-size: 1rem;
    cursor: pointer;
    text-align: center;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .${DISABLE_SCROLL_CLASS} {
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    width: var(${CSS_VAR_BODY_WIDTH}); 
  }

  @supports (font: -apple-system-body) and (-webkit-appearance: none) { img[loading="lazy"] { clip-path: inset(0.6px) } }

  .ReactModal__Body--open {
    overflow: hidden;
  }
`;
