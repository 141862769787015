type TProps = {
    variant?: 'light' | 'dark';
};

export const GoogleMethodIcon = ({ variant = 'light' }: TProps) => {
    return variant === 'light' ? (
        <svg
            width="35"
            height="24"
            viewBox="0 0 35 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect
                x="0.5"
                y="0.5"
                width="34"
                height="23"
                rx="3.5"
                fill="white"
                stroke="#D9D9D9"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.5296 15.758V12.737H18.0888C18.7277 12.737 19.267 12.5229 19.7066 12.1007L19.8121 11.9937C20.6152 11.1195 20.5624 9.75769 19.7066 8.94893C19.2787 8.52076 18.6926 8.28884 18.0888 8.30073H15.5859V15.758H16.5296ZM16.5298 11.8212V9.21654H18.1126C18.4527 9.21654 18.7751 9.34737 19.0155 9.58524C19.5255 10.0848 19.5372 10.9173 19.0448 11.4347C18.8044 11.6904 18.4644 11.8331 18.1126 11.8212H16.5298ZM24.2142 11.0541C23.8097 10.6794 23.2587 10.4891 22.5612 10.4891C21.6644 10.4891 20.9903 10.8222 20.5448 11.4823L21.3772 12.0115C21.682 11.5596 22.0982 11.3336 22.6257 11.3336C22.9598 11.3336 23.2822 11.4585 23.5342 11.6844C23.7804 11.8985 23.9211 12.2078 23.9211 12.5348V12.7549C23.5577 12.5527 23.1005 12.4456 22.5378 12.4456C21.8813 12.4456 21.3537 12.6002 20.961 12.9154C20.5683 13.2306 20.369 13.6469 20.369 14.1761C20.3573 14.6578 20.5624 15.1157 20.9259 15.425C21.2951 15.758 21.7641 15.9245 22.315 15.9245C22.9657 15.9245 23.4815 15.6331 23.8742 15.0503H23.9152V15.758H24.8179V12.6121C24.8179 11.9521 24.6186 11.4287 24.2142 11.0541ZM21.6529 14.8184C21.4594 14.6756 21.3421 14.4437 21.3421 14.194C21.3421 13.9145 21.4711 13.6825 21.7232 13.4982C21.9812 13.3138 22.3036 13.2187 22.6847 13.2187C23.2123 13.2127 23.6227 13.3317 23.9158 13.5695C23.9158 13.9739 23.7575 14.3248 23.4468 14.6221C23.1654 14.9076 22.7843 15.0681 22.3857 15.0681C22.1219 15.0741 21.8639 14.9849 21.6529 14.8184ZM26.846 17.9999L29.9995 10.6557H28.9738L27.5142 14.3189H27.4967L26.002 10.6557H24.9762L27.0453 15.4309L25.873 17.9999H26.846Z"
                fill="#3C4043"
            />
            <path
                d="M13.273 12.0828C13.273 11.7914 13.2495 11.5 13.2027 11.2146H9.22266V12.8619H11.5028C11.409 13.3911 11.1042 13.8669 10.6587 14.1642V15.2346H12.0186C12.8158 14.4913 13.273 13.3911 13.273 12.0828Z"
                fill="#4285F4"
            />
            <path
                d="M9.22211 16.2694C10.3592 16.2694 11.3205 15.8888 12.0181 15.2346L10.6582 14.1642C10.2772 14.4259 9.79068 14.5745 9.22211 14.5745C8.12013 14.5745 7.18815 13.8193 6.85404 12.8083H5.45312V13.9144C6.16824 15.3595 7.62776 16.2694 9.22211 16.2694Z"
                fill="#34A853"
            />
            <path
                d="M6.85401 12.8084C6.67814 12.2791 6.67814 11.7023 6.85401 11.167V10.0669H5.45288C4.84904 11.2741 4.84904 12.7013 5.45288 13.9085L6.85401 12.8084Z"
                fill="#FBBC04"
            />
            <path
                d="M9.22211 9.4009C9.82585 9.389 10.4061 9.62093 10.8399 10.0431L12.0474 8.81811C11.2795 8.09261 10.2713 7.69418 9.22211 7.70607C7.62776 7.70607 6.16824 8.62187 5.45312 10.0669L6.85404 11.173C7.18815 10.1561 8.12013 9.4009 9.22211 9.4009Z"
                fill="#EA4335"
            />
        </svg>
    ) : (
        <svg
            width="35"
            height="24"
            viewBox="0 0 35 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect width="35" height="24" rx="4" fill="black" />
            <rect
                x="0.5"
                y="0.5"
                width="34"
                height="23"
                rx="3.5"
                stroke="#D9D9D9"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.5296 15.7581V12.7371H18.0888C18.7277 12.7371 19.267 12.5231 19.7066 12.1008L19.8121 11.9938C20.6152 11.1196 20.5624 9.75781 19.7066 8.94905C19.2787 8.52088 18.6926 8.28896 18.0888 8.30085H15.5859V15.7581H16.5296ZM16.5298 11.8213V9.21666H18.1126C18.4527 9.21666 18.7751 9.34749 19.0155 9.58536C19.5255 10.0849 19.5372 10.9174 19.0448 11.4348C18.8044 11.6905 18.4644 11.8332 18.1126 11.8213H16.5298ZM24.2142 11.0542C23.8097 10.6796 23.2587 10.4893 22.5612 10.4893C21.6644 10.4893 20.9903 10.8223 20.5448 11.4824L21.3772 12.0116C21.682 11.5597 22.0982 11.3337 22.6257 11.3337C22.9598 11.3337 23.2822 11.4586 23.5342 11.6846C23.7804 11.8987 23.9211 12.2079 23.9211 12.535V12.755C23.5577 12.5528 23.1005 12.4458 22.5378 12.4458C21.8813 12.4458 21.3537 12.6004 20.961 12.9155C20.5683 13.2307 20.369 13.647 20.369 14.1763C20.3573 14.658 20.5624 15.1159 20.9259 15.4251C21.2951 15.7581 21.7641 15.9246 22.315 15.9246C22.9657 15.9246 23.4815 15.6332 23.8742 15.0504H23.9152V15.7581H24.8179V12.6123C24.8179 11.9522 24.6186 11.4289 24.2142 11.0542ZM21.6529 14.8185C21.4594 14.6758 21.3421 14.4438 21.3421 14.1941C21.3421 13.9146 21.4711 13.6827 21.7232 13.4983C21.9812 13.314 22.3036 13.2188 22.6847 13.2188C23.2123 13.2129 23.6227 13.3318 23.9158 13.5697C23.9158 13.974 23.7575 14.3249 23.4468 14.6222C23.1654 14.9077 22.7843 15.0682 22.3857 15.0682C22.1219 15.0742 21.8639 14.985 21.6529 14.8185ZM26.846 18L29.9995 10.6558H28.9738L27.5142 14.319H27.4967L26.002 10.6558H24.9762L27.0453 15.431L25.873 18H26.846Z"
                fill="white"
            />
            <path
                d="M13.273 12.083C13.273 11.7916 13.2495 11.5002 13.2027 11.2148H9.22266V12.862H11.5028C11.409 13.3913 11.1042 13.867 10.6587 14.1644V15.2348H12.0186C12.8158 14.4914 13.273 13.3913 13.273 12.083Z"
                fill="#4285F4"
            />
            <path
                d="M9.22211 16.2695C10.3592 16.2695 11.3205 15.8889 12.0181 15.2348L10.6582 14.1643C10.2772 14.426 9.79068 14.5747 9.22211 14.5747C8.12013 14.5747 7.18815 13.8194 6.85404 12.8085H5.45312V13.9146C6.16824 15.3596 7.62776 16.2695 9.22211 16.2695Z"
                fill="#34A853"
            />
            <path
                d="M6.85401 12.8085C6.67814 12.2793 6.67814 11.7024 6.85401 11.1672V10.0671H5.45288C4.84904 11.2743 4.84904 12.7015 5.45288 13.9087L6.85401 12.8085Z"
                fill="#FBBC04"
            />
            <path
                d="M9.22211 9.40102C9.82585 9.38913 10.4061 9.62105 10.8399 10.0433L12.0474 8.81824C11.2795 8.09273 10.2713 7.6943 9.22211 7.70619C7.62776 7.70619 6.16824 8.62199 5.45312 10.0671L6.85404 11.1732C7.18815 10.1563 8.12013 9.40102 9.22211 9.40102Z"
                fill="#EA4335"
            />
        </svg>
    );
};
