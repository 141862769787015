import { InputHTMLAttributes, ReactNode } from 'react';
import { Controller, FieldValues, Path, useFormContext } from 'react-hook-form';

import { styled, useTheme } from '@shared/ui/theme';
import { breakpoints } from '@shared/ui/theme/dimensions';
import { Body1Style, Typography } from '@shared/ui/typography';

type TProps<T extends FieldValues> = {
    startIcon?: ReactNode;
    endIcon?: ReactNode;
    helperText?: string;
    controlName: Path<T>;
    defaultValue?: string;
} & InputHTMLAttributes<HTMLInputElement>;

const ICON_SIZE = 24;

const Wrapper = styled.div`
    width: 100%;
    gap: 4px;
    display: flex;
    flex-direction: column;
`;

const IconWrapper = styled.div<{ disabled?: boolean }>`
    width: ${ICON_SIZE}px;
    height: ${ICON_SIZE}px;
    color: ${({ theme, disabled }) =>
        disabled ? theme.colors.icon.tertiary : theme.colors.icon.brand};
`;

const InputWrapper = styled.div<{ disabled?: boolean; isError?: boolean }>`
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px;
    border-radius: 16px;
    border: 1px solid
        ${({ theme, isError }) =>
            isError ? theme.colors.icon.brand : 'transparent'};
    background-color: ${({ theme, isError }) =>
        isError
            ? theme.colors.surface.negativeLight
            : theme.colors.surface.secondary};
    @media (min-width: ${breakpoints.tablet}px) {
        &:hover {
            background-color: ${({ theme, disabled }) =>
                disabled
                    ? theme.colors.surface.secondary
                    : theme.colors.surface.negativeLight};
        }
    }
    &:active {
        background-color: ${({ theme, disabled }) =>
            disabled
                ? theme.colors.surface.secondary
                : theme.colors.surface.negativeLight};
    }
`;

const HelperText = styled(Typography)`
    min-height: 16px;
    padding: 0 12px;
`;

const StyledInput = styled.input<{
    isError?: boolean;
}>`
    width: calc(100% - ${2 * ICON_SIZE + 10}px);
    border: none;
    outline: none;
    ${Body1Style}
    caret-color: ${({ theme }) => theme.colors.text.brand};
    color: ${({ theme }) => theme.colors.text.primary};
    background-color: transparent;
    &::placeholder {
        color: ${({ theme }) => theme.colors.text.secondary};
    }
    &:disabled {
        color: ${({ theme }) => theme.colors.text.tertiary};
    }
    &:-webkit-autofill {
        box-shadow: 0 0 0px 40rem
            ${({ theme, isError }) =>
                isError
                    ? theme.colors.surface.negativeLight
                    : theme.colors.surface.secondary}
            inset;
        -webkit-text-fill-color: ${({ theme }) =>
            theme.colors.text.primary} !important;
    }
`;

export const Input = <T extends FieldValues>({
    helperText,
    startIcon,
    endIcon,
    controlName,
    defaultValue,
    ...rest
}: TProps<T>) => {
    const theme = useTheme();
    const {
        control,
        formState: { errors },
    } = useFormContext<FieldValues>();
    const isError = Boolean(errors[controlName]);
    const getHelpedColor = () => {
        if (rest.disabled) {
            return theme.colors.text.tertiary;
        }
        if (isError) {
            return theme.colors.text.negative;
        }
        return theme.colors.text.primary;
    };
    return (
        <Controller
            control={control}
            name={controlName}
            render={({ field }) => (
                <Wrapper>
                    <InputWrapper isError={isError} disabled={rest.disabled}>
                        <IconWrapper disabled={rest.disabled}>
                            {startIcon}
                        </IconWrapper>
                        <StyledInput
                            {...field}
                            {...rest}
                            onKeyDown={e => {
                                if (
                                    e.key === '-' ||
                                    e.key === 'ArrowUp' ||
                                    e.key === 'ArrowDown'
                                ) {
                                    e.preventDefault();
                                }
                            }}
                            isError={isError}
                            type="text"
                        />
                        <IconWrapper disabled={rest.disabled}>
                            {endIcon}
                        </IconWrapper>
                    </InputWrapper>
                    <HelperText variant="caption1" color={getHelpedColor()}>
                        {errors[controlName]?.message?.toString() || helperText}
                    </HelperText>
                </Wrapper>
            )}
        />
    );
};
