import { useStore } from 'effector-react';
import { useCallback } from 'react';

import { QUESTIONS_AMOUNT } from '@entities/questions/model/constants';
import { useGetStage } from '@entities/questions/model/hooks';
import { $stage, setStage } from '@entities/questions/model/questions-domain';

import { Header } from './header';

type Props = {
    onSkip?: () => void;
    isReturnable: boolean;
    isTransparent: boolean;
    isProgress?: boolean;
    isSkippable?: boolean;
};

export const HeaderConnector = ({
    isReturnable,
    isSkippable,
    onSkip,
    isTransparent,
    isProgress,
}: Props) => {
    const stage = useStore($stage);
    const stageInfo = useGetStage(stage);
    const progress = isProgress
        ? ((stageInfo?.info.number || 0) / QUESTIONS_AMOUNT) * 100
        : undefined;
    const returnHandler = useCallback(() => setStage(stage - 1), [stage]);
    return (
        <Header
            isSkippable={isSkippable}
            onSkip={onSkip}
            onReturn={returnHandler}
            isReturnable={isReturnable}
            isTransparent={isTransparent}
            progress={progress}
        />
    );
};
