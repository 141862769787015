import { RouteObject } from 'react-router-dom';

import { paths } from '@shared/paths';
import { MetaTitle } from '@shared/ui/atoms/meta-title';
import { PageTemplate } from '@shared/ui/templates/page-template';

import { SuggestionsConnector } from './suggestions-connector';

export const routes: RouteObject[] = [
    {
        path: paths.suggestions,
        element: (
            <PageTemplate>
                <MetaTitle>Mooni</MetaTitle>
                <SuggestionsConnector />
            </PageTemplate>
        ),
    },
];
