export const StarIcon = () => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_364_17998)">
                <path
                    d="M5.4953 4.89328L1.24197 5.50995L1.16663 5.52528C1.05259 5.55556 0.948629 5.61555 0.865361 5.69915C0.782092 5.78274 0.722502 5.88694 0.692674 6.0011C0.662847 6.11526 0.663851 6.23529 0.695584 6.34893C0.727318 6.46257 0.788644 6.56576 0.873299 6.64795L3.95463 9.64728L3.22797 13.8839L3.2193 13.9573C3.21232 14.0752 3.23681 14.1929 3.29027 14.2983C3.34372 14.4037 3.42422 14.4929 3.52352 14.557C3.62282 14.621 3.73736 14.6575 3.8554 14.6627C3.97344 14.6679 4.09074 14.6416 4.1953 14.5866L7.9993 12.5866L11.7946 14.5866L11.8613 14.6173C11.9713 14.6606 12.0909 14.6739 12.2078 14.6558C12.3247 14.6377 12.4346 14.5888 12.5264 14.5141C12.6181 14.4395 12.6883 14.3418 12.7299 14.231C12.7714 14.1203 12.7827 14.0005 12.7626 13.8839L12.0353 9.64728L15.118 6.64728L15.17 6.59062C15.2442 6.49913 15.293 6.38958 15.3111 6.27315C15.3293 6.15671 15.3162 6.03753 15.2734 5.92777C15.2305 5.818 15.1593 5.72157 15.067 5.64829C14.9747 5.57501 14.8646 5.52751 14.748 5.51062L10.4946 4.89328L8.5933 1.03995C8.53828 0.928305 8.45311 0.834292 8.34743 0.768551C8.24174 0.702811 8.11976 0.667969 7.9953 0.667969C7.87084 0.667969 7.74886 0.702811 7.64317 0.768551C7.53749 0.834292 7.45232 0.928305 7.3973 1.03995L5.4953 4.89328Z"
                    fill="#FF8833"
                />
            </g>
            <defs>
                <clipPath id="clip0_364_17998">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
