export const LogoIcon = () => {
    return (
        <svg
            filter="drop-shadow(4.114px 4.114px 4.629px rgba(138, 52, 58, 0.15)"
            height="39"
            width="38"
            fill="none"
            viewBox="0 0 38 39"
            xmlns="http://www.w3.org/2000/svg">
            <g>
                <path
                    d="M29.1854 16.1934C29.1854 22.7754 23.8496 28.1112 17.2676 28.1112C10.6855 28.1112 5.34973 22.7754 5.34973 16.1934C5.34973 9.61132 10.6855 4.27551 17.2676 4.27551C23.8496 4.27551 29.1854 9.61132 29.1854 16.1934ZM7.60349 16.1934C7.60349 21.5307 11.9303 25.8575 17.2676 25.8575C22.6049 25.8575 26.9317 21.5307 26.9317 16.1934C26.9317 10.856 22.6049 6.52927 17.2676 6.52927C11.9303 6.52927 7.60349 10.856 7.60349 16.1934Z"
                    fill="url(#paint0_linear_261_13453)"
                />
                <path
                    d="M13.4048 6.07722C13.3225 5.86152 13.0805 5.7527 12.868 5.84302C10.9544 6.65643 9.30093 7.98579 8.0947 9.6862C6.80774 11.5004 6.08766 13.6555 6.02551 15.879C5.96336 18.1025 6.56195 20.2944 7.74557 22.1777C8.85493 23.9428 10.4315 25.3625 12.2968 26.2815C12.5039 26.3835 12.7515 26.2884 12.8458 26.0776C12.9401 25.8669 12.8452 25.6204 12.6384 25.5177C10.924 24.6666 9.47482 23.3579 8.45346 21.7328C7.35784 19.9895 6.80376 17.9605 6.86128 15.9023C6.91881 13.8442 7.58536 11.8493 8.77664 10.17C9.88718 8.60444 11.4072 7.37869 13.1665 6.62469C13.3787 6.53374 13.4872 6.29291 13.4048 6.07722Z"
                    fill="url(#paint1_linear_261_13453)"
                    fillOpacity="0.8"
                />
                <g>
                    <path
                        d="M17.1 8.71428C13.021 8.71428 9.71429 12.021 9.71429 16.1C9.71429 20.179 13.021 23.4857 17.1 23.4857C18.9541 23.4857 20.4571 24.9888 20.4571 26.8429C20.4571 28.697 18.9541 30.2 17.1 30.2C9.31279 30.2 3 23.8872 3 16.1C3 8.31278 9.31279 2 17.1 2C18.9541 2 20.4571 3.50304 20.4571 5.35714C20.4571 7.21124 18.9541 8.71428 17.1 8.71428Z"
                        fill="url(#paint2_linear_261_13453)"
                        fillOpacity="0.25"
                        fillRule="evenodd"
                    />
                    <path
                        d="M17.1 8.45714C12.879 8.45714 9.45714 11.879 9.45715 16.1C9.45715 20.321 12.879 23.7429 17.1 23.7429C18.8121 23.7429 20.2 25.1308 20.2 26.8429C20.2 28.5549 18.8121 29.9429 17.1 29.9429C9.45481 29.9429 3.25715 23.7452 3.25715 16.1C3.25714 8.4548 9.4548 2.25714 17.1 2.25714C18.8121 2.25714 20.2 3.64506 20.2 5.35714C20.2 7.06922 18.8121 8.45714 17.1 8.45714Z"
                        stroke="url(#paint3_linear_261_13453)"
                        strokeLinecap="round"
                        strokeWidth="0.514286"
                    />
                </g>
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_261_13453"
                    gradientUnits="userSpaceOnUse"
                    x1="10.1066"
                    x2="24.4286"
                    y1="28.1112"
                    y2="4.27551">
                    <stop stopColor="#FFA592" />
                    <stop offset="1" stopColor="#FF92A5" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_261_13453"
                    gradientUnits="userSpaceOnUse"
                    x1="17.4259"
                    x2="29.3054"
                    y1="27.9148"
                    y2="14.5766">
                    <stop stopColor="#FF6543" />
                    <stop offset="1" stopColor="#FF395D" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_261_13453"
                    gradientUnits="userSpaceOnUse"
                    x1="8.3223"
                    x2="17.9021"
                    y1="26.8429"
                    y2="25.3608">
                    <stop stopColor="#FFA592" />
                    <stop offset="1" stopColor="#FF92A5" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear_261_13453"
                    gradientUnits="userSpaceOnUse"
                    x1="18.1071"
                    x2="32.9008"
                    y1="-10.2536"
                    y2="22.7747">
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="white" stopOpacity="0" />
                </linearGradient>
            </defs>
        </svg>
    );
};
