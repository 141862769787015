import { TStage } from './types';

type TStages = {
    [key: number]: TStage;
};

export const stageSchema: TStages = {
    0: {
        type: 'QUESTION',
        info: {
            type: 'RADIO',
            number: 1,
            text: 'How familiar are you with tracking your menstrual cycle?',
            controlValue: {
                variants: [
                    {
                        label: 'Very familiar',
                        icon: 'BARS_HIGHT',
                        value: 'familiar',
                    },
                    {
                        label: 'Somewhat familiar',
                        icon: 'BARS_MEDIUM',
                        value: 'seme-familiar',
                    },
                    {
                        label: 'Not familiar at all',
                        icon: 'CROSS',
                        value: 'not-familiar',
                    },
                ],
            },
        },
    },
    1: {
        type: 'INFO',
        info: {
            number: 1,
            title: 'Tracking your menstrual cycle',
            description:
                "is essential for understanding your body's natural rhythm, predicting fertility, and detecting irregularities early on. It provides valuable insights into overall health, helping you make informed decisions about lifestyle, emotional well-being, and reproductive choices.",
            HeroComponent: 'ACTIVITY',
        },
    },
    2: {
        type: 'QUESTION',
        info: {
            type: 'RADIO',
            number: 2,
            text: 'How do you currently track your period?',
            controlValue: {
                variants: [
                    {
                        label: 'Calendar',
                        icon: 'CALENDAR_HEART',
                        value: 'familiar',
                    },
                    {
                        label: 'Period tracking app',
                        icon: 'APP',
                        value: 'seme-familiar',
                    },
                    {
                        label: 'Not tracking',
                        icon: 'CROSS',
                        value: 'not-familiar',
                    },
                ],
            },
        },
    },
    3: {
        type: 'INFO',
        info: {
            number: 2,
            title: 'A period tracking app',
            description:
                'simplifies cycle monitoring, offering timely reminders and insights for better reproductive health management',
            HeroComponent: 'TRACKING_APP',
            isContentBottom: true,
        },
    },
    4: {
        type: 'QUESTION',
        info: {
            type: 'RADIO',
            number: 3,
            text: 'What motivates you to track your period?',
            controlValue: {
                variants: [
                    {
                        label: 'Understanding fertility',
                        icon: 'STAR',
                        value: 'familiar',
                    },
                    {
                        label: 'Predicting the next period',
                        icon: 'CALENDAR_EVENT',
                        value: 'seme-familiar',
                    },
                    {
                        label: 'Monitoring overall health',
                        icon: 'MONITORING',
                        value: 'not-familiar',
                    },
                    {
                        label: 'All of the above',
                        icon: 'LIST_ALL',
                        value: 'not-familiar',
                    },
                ],
            },
        },
    },
    5: {
        type: 'INFO',
        info: {
            number: 3,
            title: 'According to a 5-year research study ',
            description: `conducted by Brigham and Women's Hospital, period tracking serves as a valuable tool for family planning, identifying fertile days, and contributing to proactive health management by detecting irregularities early.`,
            HeroComponent: 'GRAPHIC',
        },
    },
    6: {
        type: 'QUESTION',
        info: {
            type: 'RADIO',
            number: 4,
            text: 'How would you describe your menstrual flow?',
            controlValue: {
                variants: [
                    {
                        label: 'Light',
                        description:
                            'Minimal blood loss, suitable for panty liners',
                        icon: 'BLOOD_1',
                        value: 'familiar',
                    },
                    {
                        label: 'Moderate',
                        description:
                            'Average blood loss, regular or super-absorbent pads/tampons',
                        icon: 'BLOOD_2',
                        value: 'seme-familiar',
                    },
                    {
                        label: 'Heavy',
                        description:
                            'Substantial blood loss requires high-absorbency products',
                        icon: 'BLOOD_3',
                        value: 'not-familiar',
                    },
                ],
            },
        },
    },
    7: {
        type: 'QUESTION',
        info: {
            type: 'RADIO',
            number: 5,
            text: 'How do you manage menstrual pain or discomfort?',
            controlValue: {
                variants: [
                    {
                        label: 'Medication',
                        icon: 'PILLS',
                        value: 'familiar',
                    },
                    {
                        label: 'Natural remedies',
                        icon: 'GROW',
                        value: 'seme-familiar',
                    },
                    {
                        label: 'Rest and relaxation',
                        icon: 'BATH',
                        value: 'not-familiar',
                    },

                    {
                        label: 'Other',
                        icon: 'SUNNY',
                        value: 'not-familiar',
                    },
                ],
            },
        },
    },
    8: {
        type: 'INFO',
        info: {
            number: 5,
            title: 'According to research from the US National Institutes of Health',
            description: `Menstrual pain, impacting 20% to 50%, is a widespread challenge. Instead of relying solely on medication, prioritizing lifestyle adjustments emerges as a key strategy for holistic well-being.`,
            HeroComponent: 'RESEARCH',
        },
    },
    9: {
        type: 'QUESTION',
        info: {
            type: 'RADIO',
            number: 6,
            text: 'Have you ever experienced irregular periods?',
            controlValue: {
                variants: [
                    {
                        label: 'Yes',
                        icon: 'CHECK',
                        value: 'yes',
                    },
                    {
                        label: 'No',
                        icon: 'CROSS',
                        value: 'no',
                    },
                ],
            },
        },
    },
    10: {
        type: 'QUESTION',
        info: {
            type: 'RADIO',
            number: 7,
            text: 'Are you currently using any form of contraception?',
            controlValue: {
                variants: [
                    {
                        label: 'Yes',
                        icon: 'CHECK',
                        value: 'yes',
                    },
                    {
                        label: 'No',
                        icon: 'CROSS',
                        value: 'no',
                    },
                ],
            },
        },
    },
    11: {
        type: 'QUESTION',
        info: {
            type: 'RADIO',
            number: 8,
            text: 'How would you rate your stress level on a regular basis?',
            controlValue: {
                variants: [
                    {
                        label: 'Low',
                        icon: 'BARS_LOW',
                        value: 'low',
                    },
                    {
                        label: 'Moderate',
                        icon: 'BARS_MEDIUM',
                        value: 'moderate',
                    },
                    {
                        label: 'High',
                        icon: 'BARS_HIGHT',
                        value: 'no',
                    },
                ],
            },
        },
    },
    12: {
        type: 'QUESTION',
        info: {
            type: 'RADIO',
            number: 9,
            text: 'Do you have any specific concerns or questions about your menstrual health?',
            controlValue: {
                variants: [
                    {
                        label: 'Yes',
                        icon: 'CHECK',
                        value: 'yes',
                    },
                    {
                        label: 'No',
                        icon: 'CROSS',
                        value: 'no',
                    },
                ],
            },
        },
    },
    13: {
        type: 'INFO',
        info: {
            number: 9,
            title: 'Congratulations! ',
            description: `Оn taking the first step towards holistic health. By providing a bit more information, you'll unlock personalized insights tailored just for you. This will not only enhance your understanding of your body but also contribute to more accurate predictions for your next period.`,
            HeroComponent: 'CONGRATULATIONS',
        },
    },
    14: {
        type: 'QUESTION',
        isSkippable: true,
        info: {
            type: 'CALENDAR',
            number: 10,
            text: 'When was the first day of your last period?',
        },
    },
    15: {
        type: 'QUESTION',
        info: {
            type: 'INPUT',
            number: 11,
            text: 'How long is your typical menstrual cycle?',
            controlValue: {
                placeholder: 'e.g., 28 days',
                icon: 'CALENDAR_EVENT',
                schema: 'integer',
            },
        },
    },
    16: {
        type: 'QUESTION',
        info: {
            type: 'RADIO',
            number: 12,
            text: 'Have you recently experienced any significant changes in stress levels or lifestyle?',
            controlValue: {
                variants: [
                    {
                        label: 'Yes',
                        icon: 'CHECK',
                        value: 'yes',
                    },
                    {
                        label: 'No',
                        icon: 'CROSS',
                        value: 'no',
                    },
                ],
            },
        },
    },
    17: {
        type: 'QUESTION',
        info: {
            type: 'RADIO',
            number: 13,
            text: 'Have you recently traveled across time zones or experienced significant changes in climate?',
            controlValue: {
                variants: [
                    {
                        label: 'Yes',
                        icon: 'CHECK',
                        value: 'yes',
                    },
                    {
                        label: 'No',
                        icon: 'CROSS',
                        value: 'no',
                    },
                ],
            },
        },
    },
    18: {
        type: 'QUESTION',
        info: {
            type: 'RADIO',
            number: 14,
            text: 'How consistent are your menstrual cycles in terms of length over the past few months?',
            controlValue: {
                variants: [
                    {
                        label: 'Very consistent',
                        icon: 'BARS_HIGHT',
                        value: 'no',
                    },
                    {
                        label: 'Somewhat consistent',
                        icon: 'BARS_MEDIUM',
                        value: 'moderate',
                    },
                    {
                        label: 'Inconsistent',
                        icon: 'CROSS',
                        value: 'low',
                    },
                ],
            },
        },
        isLast: true,
    },
};
