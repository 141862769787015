import { useEffect, useState } from 'react';

import { styled, useTheme } from '@shared/ui/theme';
import { Typography } from '@shared/ui/typography';
import { secondsToTime } from '@shared/utils/time';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

type TProps = {
    timeToCount: number;
    variant: 'primary' | 'secondary';
};

export const Timer = ({ timeToCount, variant }: TProps) => {
    const theme = useTheme();
    const [time, setTime] = useState<number>(timeToCount);
    useEffect(() => {
        const interval = setInterval(() => {
            setTime(prev => {
                if (prev <= 0) {
                    clearInterval(interval);
                    return prev;
                }
                return prev - 1;
            });
        }, 1000);
        return () => clearInterval(interval);
    }, []);
    return (
        <Wrapper>
            <Typography variant="title1" color="#FF8D94">
                {secondsToTime(time).minutesTime}:
                {secondsToTime(time).secondsTime}
            </Typography>
            <Typography
                variant={variant === 'primary' ? 'caption1' : 'footnote'}>
                minutes{' '}
                <Typography
                    variant="caption1"
                    color={theme.colors.text.secondary}>
                    &
                </Typography>{' '}
                seconds
            </Typography>
        </Wrapper>
    );
};
