import { css, styled } from '@shared/ui/theme';

import heartBig from './assets/heart-big.webp';
import heartMedium from './assets/heart-medium.webp';
import heartSmall from './assets/heart-small.webp';
import phoneImg from './assets/phone.webp';

const Wrapper = styled.div`
    display: flex;
    position: relative;
    margin-top: 70px;
    margin-bottom: 80%;
    max-width: 450px;
    height: 100%;
`;

const PhoneWrapper = styled.div`
    z-index: 3;
    display: flex;
    justify-content: center;
    position: absolute;
    width: 100%;
    top: 100%;
`;

const AnimationMixin = css`
    animation-direction: alternate;
    @keyframes diagonal {
        from {
            top: 10%; /* от: left: 0px */
            right: 5%;
        }
        to {
            top: 40%; /* до: left: 100%-50px */
            right: 50%;
        }
    }
    @keyframes vertical1 {
        from {
            top: 5%; /* от: left: 0px */
        }
        to {
            top: 40%; /* до: left: 100%-50px */
        }
    }
    @keyframes vertical2 {
        from {
            top: 40%; /* от: left: 0px */
        }
        to {
            top: 10%; /* до: left: 100%-50px */
        }
    }
`;

const HeartSmallWrapper = styled.div`
    z-index: 2;
    position: absolute;
    animation: diagonal 20s infinite linear;
    ${AnimationMixin}
`;

const HeartBigWrapper = styled.div`
    position: absolute;
    left: -200px;
    animation: vertical1 20s infinite linear;
    ${AnimationMixin}
`;

const HeartWrapper = styled.div`
    right: -100px;
    position: absolute;
    animation: vertical2 20s infinite linear;
    ${AnimationMixin}
`;

export const TrackingApp = () => {
    return (
        <>
            <Wrapper>
                <PhoneWrapper>
                    <img src={phoneImg} alt="" height="400px" />
                </PhoneWrapper>
            </Wrapper>
            <HeartWrapper>
                <img src={heartMedium} alt="" />
            </HeartWrapper>
            <HeartSmallWrapper>
                <img src={heartSmall} alt="" />
            </HeartSmallWrapper>
            <HeartBigWrapper>
                <img src={heartBig} alt="" />
            </HeartBigWrapper>
        </>
    );
};
