import { LogoIcon, MooniIcon } from '@shared/ui/icon';
import { styled } from '@shared/ui/theme';

const Wrapper = styled.div`
    display: flex;
    font-display: row;
    gap: 4px;
    align-items: center;
`;

const LogoWrapper = styled.div`
    width: 32px;
    height: 32px;
`;

export const Logo = () => {
    return (
        <Wrapper>
            <LogoWrapper>
                <LogoIcon />
            </LogoWrapper>
            <MooniIcon />
        </Wrapper>
    );
};
