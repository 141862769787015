import { useState } from 'react';

import { Button } from '@shared/ui/atoms/button';
import { Calendar } from '@shared/ui/organisms/calendar';

type TProps = {
    onSubmit: (value: string) => void;
};

export const CalendarForm = ({ onSubmit }: TProps) => {
    const [selected, setSelected] = useState<Date | undefined>(undefined);
    const today = new Date();
    const fromDate = new Date(new Date().setDate(today.getDate() - 40));
    return (
        <>
            <Calendar
                selected={selected}
                setSelected={setSelected}
                fromDate={fromDate}
                toDate={today}
            />

            <Button
                fullWidth
                type="submit"
                style={{ marginBottom: '16px' }}
                onClick={() => onSubmit(selected?.toDateString() || '')}
                disabled={!selected}>
                Select
            </Button>
        </>
    );
};
