import { useNavigate } from 'react-router-dom';

import { paths } from '@shared/paths';
import { HeaderConnector } from '@widgets/header';

import { EmailPage } from './email-page';

export const EmailPageConnector = () => {
    const navigate = useNavigate();
    return (
        <EmailPage
            HeaderComponent={
                <HeaderConnector
                    isReturnable={false}
                    isSkippable={true}
                    onSkip={() => navigate(paths.result)}
                    isTransparent
                />
            }
            onContinue={() => navigate(paths.result)}
        />
    );
};
