import { RoundCheck } from '@shared/ui/icon';
import { styled } from '@shared/ui/theme';
import { Typography } from '@shared/ui/typography';

type TProps = {
    text: string;
    isVisible: boolean;
};

const Wrapper = styled.div<{ isVisible: boolean }>`
    display: flex;
    flex-direction: row;
    gap: 8px;
    width: 215px;
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    transition: opacity 2s linear;
`;

const IconWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 1px;
    min-width: 18px;
    height: 18px;
`;

export const CheckedText = ({ text, isVisible }: TProps) => {
    return (
        <Wrapper isVisible={isVisible}>
            <IconWrapper>
                <RoundCheck />
            </IconWrapper>
            <Typography variant="body1" fontWeight={300}>
                {text}
            </Typography>
        </Wrapper>
    );
};
