export const secondsToTime = (seconds: number) => {
    const minutesTime = Math.floor((seconds % 3600) / 60)
        .toString()
        .padStart(2, '0');
    const secondsTime = Math.floor(seconds % 60)
        .toString()
        .padStart(2, '0');

    return {
        minutesTime,
        secondsTime,
    };
};
