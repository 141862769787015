import { styled } from '@shared/ui/theme';
import { breakpoints } from '@shared/ui/theme/dimensions';

import img from './img.webp';

const Wrapper = styled.div`
    display: flex;
    margin-left: -70px;
    margin-top: 40px;
    @media (min-width: ${breakpoints.tablet}px) {
        margin-left: 0;
        justify-content: center;
    }
    max-height: 300px;
`;

export const Activity = () => {
    return (
        <Wrapper>
            <img src={img} alt="" width="320px" height="312px" />
        </Wrapper>
    );
};
