export const RoundCheck = () => {
    return (
        <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" width="16" height="16" rx="8" fill="#FF9494" />
            <path
                d="M7.07143 12C6.88347 12 6.70005 11.9334 6.56644 11.8144L3.71084 9.26858C3.0428 8.64523 4.12299 7.81411 4.72083 8.36886L7.03973 10.4366L12.2482 5.21381C12.8641 4.59853 13.9307 5.4458 13.3148 6.05906L7.6036 11.7862C7.47226 11.9173 7.28656 11.9939 7.09181 12C7.08502 12 7.07822 12 7.07143 12Z"
                fill="white"
                fillOpacity="0.94"
            />
        </svg>
    );
};
