export const GrowIcon = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 12H9C7.4087 12 5.88258 11.3679 4.75736 10.2426C3.63214 9.11742 3 7.5913 3 6V4H6C7.5913 4 9.11742 4.63214 10.2426 5.75736C11.3679 6.88258 12 8.4087 12 10V20M12 14C12 12.4087 12.6321 10.8826 13.7574 9.75736C14.8826 8.63214 16.4087 8 18 8H21V9C21 10.5913 20.3679 12.1174 19.2426 13.2426C18.1174 14.3679 16.5913 15 15 15H12"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
