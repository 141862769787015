import { DayPicker, SelectSingleEventHandler } from 'react-day-picker';

import { styled, useTheme } from '@shared/ui/theme';

import selectedSvg from './selected.svg';

type TProps = {
    selected: Date | undefined;
    toDate: Date;
    fromDate: Date;
    setSelected: SelectSingleEventHandler;
};

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 3px 21px 0px #4e4e4e1a;
    border-radius: 16px;
    .rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
        background: #ff8d9414 !important;
        color: ${({ theme }) => theme.colors.text.brand};
    }
    .rdp-day_selected {
        color: ${({ theme }) => theme.colors.text.invertPrimary} !important;
    }
`;

export const Calendar = ({
    selected,
    toDate,
    fromDate,
    setSelected,
}: TProps) => {
    const theme = useTheme();
    return (
        <Wrapper>
            <DayPicker
                mode="single"
                selected={selected}
                onSelect={setSelected}
                toDate={toDate}
                fromDate={fromDate}
                styles={{
                    nav_icon: { color: theme.colors.text.brand },
                    caption_label: {
                        fontWeight: 500,
                        fontSize: '16px',
                        lineHeight: '24px',
                        fontFamily: 'Poppins',
                    },
                    head_cell: {
                        color: theme.colors.text.primary,
                        fontFamily: 'Poppins',
                        fontSize: '12px',
                        fontWeight: 400,
                        lineHeight: '16px',
                        letterSpacing: '1px',
                    },
                    button: {
                        backdropFilter: '',
                    },
                }}
                modifiersStyles={{
                    today: {
                        color: theme.colors.text.brand,
                        fontFamily: 'Poppins',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        lineHeight: '24px',
                        letterSpacing: '0.15px',
                    },
                    selected: {
                        color: theme.colors.text.invertPrimary,
                        backgroundImage: `url(${selectedSvg})`,
                        backgroundColor: 'transparent',
                        backgroundPosition: '-13px -7px',
                    },
                }}
            />
        </Wrapper>
    );
};
