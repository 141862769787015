import { css } from 'styled-components';

import { styled } from '@shared/ui/theme';

export const Title1Style = css`
    font-family: var(--g-font);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.15px;
`;

export const Title1 = styled.span`
    ${Title1Style}
`;

export const Title3Style = css`
    font-family: var(--g-font);

    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px; /* 130% */
    letter-spacing: 0.1px;
`;

export const Title3 = styled.span`
    ${Title3Style}
`;
export const Body1Style = css`
    font-family: var(--g-font);

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    letter-spacing: 0.1px;
`;

export const Body1 = styled.span`
    ${Body1Style}
`;

export const Button1Style = css`
    font-family: var(--g-font);

    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: 0.15px;
`;

export const Button1 = styled.span`
    ${Button1Style}
`;

export const Button2Style = css`
    font-family: var(--g-font);

    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 171.429% */
    letter-spacing: 0.15px;
`;

export const Button2 = styled.span`
    ${Button2Style}
`;

export const Caption1Style = css`
    font-family: var(--g-font);

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    letter-spacing: 0.4px;
`;

export const Caption1 = styled.span`
    ${Caption1Style}
`;

export const FootnoteStyle = css`
    font-family: var(--g-font);

    letter-spacing: 0%;

    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 160% */
`;

export const Footnote = styled.span`
    ${FootnoteStyle}
`;
