import { RouteObject } from 'react-router-dom';

import { routes as emailRoutes } from '@pages/email';
import { routes as homeRoutes } from '@pages/home';
import { routes as paywallRoutes } from '@pages/paywall';
import { routes as quizRoutes } from '@pages/quiz';
import { routes as resultRoutes } from '@pages/result';
import { routes as suggestionsRoutes } from '@pages/suggestions';

export const routes: RouteObject[] = [
    ...quizRoutes,
    ...homeRoutes,
    ...suggestionsRoutes,
    ...emailRoutes,
    ...resultRoutes,
    ...paywallRoutes,
];
