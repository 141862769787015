import { ReactNode } from 'react';

import { styled } from '@shared/ui/theme';

type Props = {
    HeaderComponent: ReactNode;
    ContentComponent: ReactNode;
    ControlComponent: ReactNode;
    children?: ReactNode;
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 16px;
    height: 100%;
    width: 100%;
    background: ${({ theme }) => theme.colors.background.primary};
`;

const MainWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const ContentWrapper = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background: ${({ theme }) => theme.colors.background.primary};
`;

const HeaderWrapper = styled.div`
    width: 100%;
`;

const ControlWrapper = styled.div`
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    padding-bottom: 8px;
    padding-top: 16px;
    width: 100%;
`;

export const ControlPageTemplate = ({
    HeaderComponent,
    ContentComponent,
    ControlComponent,
    children,
}: Props) => {
    return (
        <Wrapper>
            <MainWrapper>
                <HeaderWrapper>{HeaderComponent}</HeaderWrapper>
                <ContentWrapper>{ContentComponent}</ContentWrapper>
            </MainWrapper>
            <ControlWrapper>{ControlComponent}</ControlWrapper>
            {children}
        </Wrapper>
    );
};
