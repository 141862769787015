import {
    AmexMethodIcon,
    AppleMethodIcon,
    DiscoverMethodIcon,
    GoogleMethodIcon,
    MastercardMethodIcon,
    StripeMethodIcon,
    VisaMethodIcon,
} from '@shared/ui/icon';
import { styled } from '@shared/ui/theme';

type TProps = {
    variant: 'dark' | 'light';
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
`;

export const MethodList = ({ variant }: TProps) => {
    return (
        <Wrapper>
            <AppleMethodIcon variant={variant} />
            <GoogleMethodIcon variant={variant} />
            <StripeMethodIcon variant={variant} />
            <VisaMethodIcon variant={variant} />
            <MastercardMethodIcon variant={variant} />
            <AmexMethodIcon variant={variant} />
            <DiscoverMethodIcon variant={variant} />
        </Wrapper>
    );
};
