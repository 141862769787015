import { styled } from '@shared/ui/theme';

import heroImg from './assets/hero.webp';
import starBigImg from './assets/star-big.webp';
import starSmallImg from './assets/star-small.webp';

const Wrapper = styled.div`
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 16px;
`;

const Blur = styled.div`
    position: absolute;
    z-index: 3;
    bottom: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(
        180deg,
        rgba(253, 249, 249, 0) 0%,
        ${({ theme }) => theme.colors.background.primary} 100%
    );
`;

const StarSmallWrapper = styled.div`
    position: absolute;
    z-index: 1;
    left: -20%;
`;

const StarBigWrapper = styled.div`
    position: absolute;
    z-index: 2;
    right: -35%;
    top: -10%;
`;

export const Hero = () => {
    return (
        <Wrapper>
            <Blur />
            <img
                src={heroImg}
                alt="hero"
                width="282px"
                height="282px"
                style={{ zIndex: 4 }}
            />
            <StarSmallWrapper>
                <img src={starSmallImg} alt="" width="323px" height="323px" />
            </StarSmallWrapper>
            <StarBigWrapper>
                <img src={starBigImg} alt="" width="400px" height="400px" />
            </StarBigWrapper>
        </Wrapper>
    );
};
