import { ReactNode, useRef, useState } from 'react';

import { CheckedItem } from '@entities/paywall/ui/checked-item';
import { Hero } from '@entities/paywall/ui/hero';
import { ItemList } from '@entities/paywall/ui/item-list';
import { Plan } from '@entities/paywall/ui/plan';
import { QuestionItem } from '@entities/paywall/ui/question-item';
import { ReviewCard } from '@entities/paywall/ui/review-card';
import { Timer } from '@entities/paywall/ui/timer/timer';
import { Button } from '@shared/ui/atoms/button';
import { PaywallPageTemplate } from '@shared/ui/templates/paywall-page-template';
import { styled, useTheme } from '@shared/ui/theme';
import { breakpoints } from '@shared/ui/theme/dimensions';
import { Typography } from '@shared/ui/typography';
import { Footer } from '@widgets/footer';
import { ScrollListener } from '@widgets/scroll-listener';

import { answerText, checkedText, reviewText } from './data';

type Props = {
    HeaderComponent: ReactNode;
    onContinue: () => void;
};

const HeaderWrapper = styled.div`
    z-index: 99;
    width: 100%;
    position: sticky;
    top: 0;
`;

const TimerWrapper = styled.div`
    margin-top: 16px;
`;

const StickyTimer = styled.div<{ isVisible: boolean }>`
    z-index: 900;
    position: sticky;
    display: flex;
    justify-content: space-between;
    @media (min-width: ${breakpoints.tablet}px) {
        justify-content: space-evenly;
    }
    top: 45px;
    width: 100%;
    height: ${({ isVisible }) => (isVisible ? 'auto' : '0px')};
    visibility: ${({ isVisible }) => !isVisible && 'hidden'};
    background-color: ${({ theme }) => theme.colors.background.primary};
    backdrop-filter: blur(10px);
    padding: ${({ isVisible }) => (isVisible ? '16px 20px' : '0px')};
`;

export const PaywallPage = ({ HeaderComponent, onContinue }: Props) => {
    const theme = useTheme();
    const [isTimerHidden, setIsTimerHidden] = useState(true);
    const startBlockRef = useRef<HTMLDivElement | null>(null);
    const endBlockRef = useRef<HTMLDivElement | null>(null);
    const timerRef = useRef<HTMLDivElement | null>(null);
    const [selectedPlan, setSelectedPlan] = useState<string>('3');
    return (
        <PaywallPageTemplate
            HeaderComponent={
                <>
                    <HeaderWrapper>{HeaderComponent}</HeaderWrapper>
                    <ScrollListener
                        startBlockRef={startBlockRef}
                        endBlockRef={endBlockRef}
                        blockRef={timerRef}
                        onEnter={() => setIsTimerHidden(false)}
                        onExit={() => setIsTimerHidden(true)}>
                        <StickyTimer ref={timerRef} isVisible={!isTimerHidden}>
                            <Timer timeToCount={600} variant="secondary" />
                            <Button
                                variant="contained"
                                onClick={() =>
                                    endBlockRef?.current?.scrollIntoView({
                                        behavior: 'smooth',
                                    })
                                }>
                                GET MY PLAN
                            </Button>
                        </StickyTimer>
                    </ScrollListener>
                </>
            }
            HeroComponent={<Hero />}
            InfoComponent={
                <>
                    <Typography variant="title1">
                        We have prepared a special unique offer for you
                    </Typography>
                    <Typography
                        variant="title3"
                        color={theme.colors.text.secondary}
                        fontWeight={400}>
                        Get access to the full info about your reproductive
                        health
                    </Typography>
                    <TimerWrapper ref={startBlockRef}>
                        <Timer timeToCount={600} variant="primary" />
                    </TimerWrapper>
                </>
            }
            CheckedComponent={
                <>
                    <Typography variant="title1">What you get!</Typography>
                    <ItemList
                        gap={24}
                        items={checkedText}
                        render={items => <CheckedItem {...items} />}
                    />
                </>
            }
            PlanLightComponent={
                <Plan
                    variant="light"
                    selectedPlan={selectedPlan}
                    onChangePlan={setSelectedPlan}
                    onSubmit={() => null}
                />
            }
            QuestionComponent={
                <>
                    <Typography variant="title1">
                        Answer your questions
                    </Typography>
                    <ItemList
                        gap={20}
                        items={answerText}
                        render={items => <QuestionItem {...items} />}
                    />
                </>
            }
            ReviewComponent={
                <>
                    <Typography variant="title1">
                        Reviews our clients
                    </Typography>
                    {reviewText.map(item => (
                        <ReviewCard
                            stars={5}
                            nickname={item.author}
                            title={item.title}
                            description={item.description}
                        />
                    ))}
                </>
            }
            DarkPlanComponent={
                <div style={{ scrollMarginTop: '150px' }} ref={endBlockRef}>
                    <Plan
                        variant="dark"
                        selectedPlan={selectedPlan}
                        onChangePlan={setSelectedPlan}
                        onSubmit={() => null}
                    />
                </div>
            }
            FooterComponent={<Footer variant="light" />}
        />
    );
};
