import { ReactNode } from 'react';

import { styled } from '@shared/ui/theme';
import { Typography } from '@shared/ui/typography';

import img from './img.webp';

type TProps = {
    title: string;
    description: ReactNode;
};

const Wrapper = styled.div`
    position: relative;
    padding: 16px;
    border-radius: 16px;
    background-color: #ff8d9414;
    box-shadow: 0px 3px 21px 0px rgba(78, 78, 78, 0.1);
    overflow: hidden;
`;

const TextWrapper = styled.div`
    z-index: 2;
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 8px;
`;

const ClippedImg = styled.div`
    position: absolute;
    opacity: 0.24;
    right: -50px;
    top: 0;
`;

export const Banner = ({ title, description }: TProps) => {
    return (
        <Wrapper>
            <TextWrapper>
                <Typography variant="button1">{title}</Typography>
                <Typography variant="caption1">{description}</Typography>
            </TextWrapper>
            <ClippedImg>
                <img src={img} alt="" width="143px" height="127px" />
            </ClippedImg>
        </Wrapper>
    );
};
