import { styled, useTheme } from '@shared/ui/theme';
import { Typography } from '@shared/ui/typography';

type TProps = {
    title: string;
    description: string;
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
`;

export const QuestionItem = ({ title, description }: TProps) => {
    const theme = useTheme();
    return (
        <Wrapper>
            <Typography variant="title3">{title}</Typography>
            <Typography variant="body1" color={theme.colors.text.secondary}>
                {description}
            </Typography>
        </Wrapper>
    );
};
