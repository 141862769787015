import { ReactNode } from 'react';

import { RadioButton } from '@shared/ui/atoms/radio-button';
import { styled } from '@shared/ui/theme';

//TODO multi chose logic

type TItem = {
    label: string;
    icon?: ReactNode;
    description?: string;
    value: string;
};

type TProps = {
    items: TItem[];
    isMultiChose?: boolean;
    onSubmit: (value: number) => void;
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

export const RadioGroup = ({ items, isMultiChose, onSubmit }: TProps) => {
    return (
        <Wrapper>
            {items.map((item, index) => (
                <RadioButton
                    key={index}
                    fullWidth
                    isSelected={false}
                    onClick={() => {
                        if (!isMultiChose) {
                            onSubmit(index);
                        }
                    }}
                    description={item.description}
                    startIcon={item.icon}>
                    {item.label}
                </RadioButton>
            ))}
        </Wrapper>
    );
};
