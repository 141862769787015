type TProps = {
    variant?: 'light' | 'dark';
};

export const MastercardMethodIcon = ({ variant = 'light' }: TProps) => {
    return variant === 'light' ? (
        <svg
            width="35"
            height="24"
            viewBox="0 0 35 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect
                x="0.5"
                y="0.5"
                width="34"
                height="23"
                rx="3.5"
                fill="white"
                stroke="#D9D9D9"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.75 17.1569C16.5584 18.1852 15.0128 18.806 13.3238 18.806C9.55511 18.806 6.5 15.7154 6.5 11.903C6.5 8.09057 9.55511 5 13.3238 5C15.0128 5 16.5584 5.62075 17.75 6.64903C18.9416 5.62075 20.4872 5 22.1762 5C25.9449 5 29 8.09057 29 11.903C29 15.7154 25.9449 18.806 22.1762 18.806C20.4872 18.806 18.9416 18.1852 17.75 17.1569Z"
                fill="#ED0006"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.75 17.1569C19.2172 15.8908 20.1475 14.0068 20.1475 11.903C20.1475 9.79917 19.2172 7.91517 17.75 6.64903C18.9416 5.62075 20.4872 5 22.1762 5C25.9449 5 29 8.09057 29 11.903C29 15.7154 25.9449 18.806 22.1762 18.806C20.4872 18.806 18.9416 18.1852 17.75 17.1569Z"
                fill="#F9A000"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.7491 6.64917C19.2163 7.9153 20.1466 9.79929 20.1466 11.9031C20.1466 14.0069 19.2163 15.8909 17.7491 17.157C16.2819 15.8909 15.3516 14.0069 15.3516 11.9031C15.3516 9.79929 16.2819 7.9153 17.7491 6.64917Z"
                fill="#FF5E00"
            />
        </svg>
    ) : (
        <svg
            width="35"
            height="24"
            viewBox="0 0 35 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect width="35" height="24" rx="4" fill="black" />
            <rect
                x="0.5"
                y="0.5"
                width="34"
                height="23"
                rx="3.5"
                stroke="#D9D9D9"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.75 17.1569C16.5584 18.1852 15.0128 18.806 13.3238 18.806C9.55511 18.806 6.5 15.7154 6.5 11.903C6.5 8.09057 9.55511 5 13.3238 5C15.0128 5 16.5584 5.62075 17.75 6.64903C18.9416 5.62075 20.4872 5 22.1762 5C25.9449 5 29 8.09057 29 11.903C29 15.7154 25.9449 18.806 22.1762 18.806C20.4872 18.806 18.9416 18.1852 17.75 17.1569Z"
                fill="#ED0006"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.75 17.1569C19.2172 15.8908 20.1475 14.0068 20.1475 11.903C20.1475 9.79917 19.2172 7.91517 17.75 6.64903C18.9416 5.62075 20.4872 5 22.1762 5C25.9449 5 29 8.09057 29 11.903C29 15.7154 25.9449 18.806 22.1762 18.806C20.4872 18.806 18.9416 18.1852 17.75 17.1569Z"
                fill="#F9A000"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.7491 6.64905C19.2163 7.91518 20.1466 9.79917 20.1466 11.903C20.1466 14.0068 19.2163 15.8908 17.7491 17.1569C16.2819 15.8908 15.3516 14.0068 15.3516 11.903C15.3516 9.79917 16.2819 7.91518 17.7491 6.64905Z"
                fill="#FF5E00"
            />
        </svg>
    );
};
