import { RadioOffIcon, RadioOnIcon } from '@shared/ui/icon';
import { css, styled, useTheme } from '@shared/ui/theme';
import { Typography } from '@shared/ui/typography';

type TVariant = 'dark' | 'light';

type TProps = {
    isSelected: boolean;
    variant: TVariant;
    title: string;
    integerPrice: number;
    floatPrice: number;
    initialPrice: number;
    price: number;
    currency: string;
    onClick: () => void;
};

const DarkBgMixin = css`
    background: rgba(255, 255, 255, 0.1);
`;

const LightBgMixin = css<{ isSelected: boolean }>`
    background-color: ${({ isSelected, theme }) =>
        isSelected ? '#FFE8E8' : theme.colors.surface.secondary};
`;

const Wrapper = styled.div<{ isSelected: boolean; variant: TVariant }>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 12px;
    ${({ variant }) => (variant === 'light' ? LightBgMixin : DarkBgMixin)}
    border: 3px solid
        ${({ isSelected }) => (isSelected ? '#ff9494' : 'transparent')};
    border-radius: 16px;
    cursor: pointer;
`;

const ControlWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
`;

const RadioWrapper = styled.div<{ variant: TVariant }>`
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${({ variant, theme }) =>
        variant === 'light' ? '#2222223D' : theme.colors.background.primary};
    height: 24px;
    width: 24px;
`;

const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const PriceWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
`;

const RightWrapper = styled.div<{ variant: TVariant }>`
    display: flex;
    flex-direction: row;
    padding: 4px 8px;
    background-color: ${({ theme, variant }) =>
        variant === 'light'
            ? theme.colors.background.primary
            : 'rgba(255, 255, 255, 0.08)'};
    border-radius: 12px;
`;

const LineTypography = styled(Typography)`
    text-decoration: line-through;
`;

const IntegerTypography = styled(Typography)`
    font-size: 54px;
    font-style: normal;
    font-weight: 400;
    line-height: 48px; /* 88.889% */
    letter-spacing: 0.18px;
`;

const SubTypography = styled(Typography)`
    font-size: 8px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
`;

export const PlanCard = ({
    isSelected,
    variant,
    title,
    integerPrice,
    floatPrice,
    initialPrice,
    price,
    currency,
    onClick,
}: TProps) => {
    const theme = useTheme();
    const getTextColor = () => {
        if (variant === 'dark' && isSelected) {
            return '#fff';
        }
        if (variant === 'dark') {
            return 'rgba(206, 206, 206, 0.60)';
        }
        if (isSelected) {
            return '#222222F0';
        }
        return '#22222299';
    };
    return (
        <Wrapper isSelected={isSelected} variant={variant} onClick={onClick}>
            <ControlWrapper>
                <RadioWrapper variant={variant}>
                    {isSelected ? <RadioOnIcon /> : <RadioOffIcon />}
                </RadioWrapper>
                <InfoWrapper>
                    <Typography variant="button1" color={getTextColor()}>
                        {title}
                    </Typography>
                    <PriceWrapper>
                        <Typography
                            color={
                                variant === 'light'
                                    ? theme.colors.text.secondary
                                    : '#FFFFFF99'
                            }>
                            {currency}
                            {price}
                        </Typography>
                        <LineTypography
                            color={
                                variant === 'light' ? '#6A6A6ACC' : '#FFFFFF99'
                            }>
                            {currency}
                            {initialPrice}
                        </LineTypography>
                    </PriceWrapper>
                </InfoWrapper>
            </ControlWrapper>
            <RightWrapper variant={variant}>
                <Typography
                    color={
                        variant === 'light'
                            ? theme.colors.text.secondary
                            : theme.colors.text.invertPrimary
                    }
                    variant="button1">
                    {currency}
                </Typography>
                <IntegerTypography
                    color={
                        variant === 'light'
                            ? theme.colors.text.secondary
                            : theme.colors.text.invertPrimary
                    }>
                    {integerPrice}
                </IntegerTypography>
                <InfoWrapper>
                    <Typography
                        color={
                            variant === 'light'
                                ? theme.colors.text.secondary
                                : theme.colors.text.invertPrimary
                        }
                        variant="button1">
                        {floatPrice}
                    </Typography>
                    <SubTypography variant="footnote" color={getTextColor()}>
                        PER DAY
                    </SubTypography>
                </InfoWrapper>
            </RightWrapper>
        </Wrapper>
    );
};
