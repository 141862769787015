import { ReactNode, useEffect, useState } from 'react';

import { ProgressBar } from '@shared/ui/molecules/progress-bar';
import { styled, useTheme } from '@shared/ui/theme';
import { breakpoints } from '@shared/ui/theme/dimensions';
import { Typography } from '@shared/ui/typography';

import heart from './assets/heart.webp';
import heart1 from './assets/heart1.webp';
import heart2 from './assets/heart2.webp';
import heart3 from './assets/heart3.webp';
import heart4 from './assets/heart4.webp';

type Props = {
    HeaderComponent: ReactNode;
    onContinue: () => void;
};

const Wrapper = styled.div`
    padding: 0 16px;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    background: ${({ theme }) => theme.colors.background.primary};
`;

const HeaderWrapper = styled.div`
    width: 100%;
`;

const InfoWrapper = styled.div`
    z-index: 10;
    @media (min-width: ${breakpoints.smallTablet}px) {
        text-align: center;
    }
`;

const HeaderTypography = styled(Typography)`
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
`;

const ControlWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    margin-bottom: 16px;
    @media (min-width: ${breakpoints.smallTablet}px) {
        text-align: center;
    }
`;

const HeartWrapper = styled.div`
    position: absolute;
    @keyframes vertical5 {
        from {
            top: 5%; /* от: left: 0px */
            left: 5%;
        }
        to {
            top: 25%; /* до: left: 100%-50px */
            left: 10%;
        }
    }
    animation: vertical5 6s infinite linear;
    animation-direction: alternate;
`;

const Heart1Wrapper = styled.div`
    position: absolute;
    top: 20%;
    left: 60%;
    @keyframes vertical {
        from {
            top: 20%; /* от: left: 0px */
        }
        to {
            top: 23%; /* до: left: 100%-50px */
        }
    }
    animation: vertical 2s infinite linear;
    animation-direction: alternate;
`;

const Heart2Wrapper = styled.div`
    position: absolute;
    top: 15%;
    left: 80%;
    @keyframes vertical2 {
        from {
            top: 15%; /* от: left: 0px */
        }
        to {
            top: 35%; /* до: left: 100%-50px */
        }
    }
    animation: vertical2 10s infinite linear;
    animation-direction: alternate;
`;

const Heart3Wrapper = styled.div`
    position: absolute;
    top: 60%;
    left: 80%;
    @keyframes vertical3 {
        from {
            top: 60%; /* от: left: 0px */
        }
        to {
            top: 70%; /* до: left: 100%-50px */
        }
    }
    animation: vertical3 7s infinite linear;
    animation-direction: alternate;
`;

const Heart4Wrapper = styled.div`
    position: absolute;
    top: 70%;
    left: -5%;
    @keyframes vertical4 {
        from {
            top: 70%; /* от: left: 0px */
        }
        to {
            top: 80%; /* до: left: 100%-50px */
        }
    }
    animation: vertical4 5s infinite linear;
    animation-direction: alternate;
`;

export const Home = ({ HeaderComponent, onContinue }: Props) => {
    const theme = useTheme();
    const [progress, setProgress] = useState<number>(0);
    useEffect(() => {
        const interval = setInterval(() => {
            setProgress(prev => {
                if (prev >= 100) {
                    clearInterval(interval);
                    onContinue();
                    return 100;
                }
                return prev + 1;
            });
        }, 70);
        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <Wrapper>
            <HeaderWrapper>{HeaderComponent}</HeaderWrapper>
            <InfoWrapper>
                <Typography variant="title1" fontWeight={300}>
                    Take the first quiz, merging <br />
                    <HeaderTypography>AI technology</HeaderTypography> and
                    <br />
                    <HeaderTypography>OB-GYN expertise</HeaderTypography>.<br />
                    Gain precision insights for <br /> informed well-being
                </Typography>
            </InfoWrapper>
            <ControlWrapper>
                <Typography variant="body1" style={{ width: '100%' }}>
                    Discover the full potential of your <br /> reproductive
                    health!
                </Typography>
                <Typography color={theme.colors.text.brand}>
                    {progress}%
                </Typography>
                <ProgressBar progress={progress} />
            </ControlWrapper>
            <HeartWrapper>
                <img src={heart} alt="" />
            </HeartWrapper>
            <Heart1Wrapper>
                <img src={heart1} alt="" />
            </Heart1Wrapper>
            <Heart2Wrapper>
                <img src={heart2} alt="" />
            </Heart2Wrapper>
            <Heart3Wrapper>
                <img src={heart3} alt="" />
            </Heart3Wrapper>
            <Heart4Wrapper>
                <img src={heart4} alt="" />
            </Heart4Wrapper>
        </Wrapper>
    );
};
