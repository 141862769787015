import { ReactNode, useCallback } from 'react';

import { styled } from '@shared/ui/theme';
import { Typography } from '@shared/ui/typography';

import symbolDarkImg from './assets/symbol-dark.webp';
import symbolLightImg from './assets/symbol-light.webp';
import symbolSelectedImg from './assets/symbol-selected.webp';

type TProps = {
    children: ReactNode;
    variant: 'light' | 'dark';
    isSelected: boolean;
};

const Wrapper = styled.div<{ bgColor: string }>`
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    background-color: ${({ bgColor }) => bgColor};
    padding: 8px 2px 2px 2px;
    gap: 4px;
    overflow: hidden;
    align-items: center;
    box-shadow: 0px 3px 21px 0px #4e4e4e1a;
`;

const PlanWrapper = styled.div<{ variant: 'light' | 'dark' }>`
    background-color: ${({ theme, variant }) =>
        variant === 'dark' ? '#222' : theme.colors.background.primary};
    border-radius: 16px;
    width: 100%;
`;

const TitleTypography = styled(Typography)`
    z-index: 2;
    font-size: 12px;
    font-style: italic;
    font-weight: 700;
    line-height: 12px; /* 100% */
    text-transform: uppercase;
    text-align: center;
    color: ${({ theme }) => theme.colors.background.primary};
    background-color: ${({ theme }) => theme.colors.text.brand};
    backdrop-filter: blur(2px);
    padding: 4px 8px;
    border-radius: 12px;
`;

const Animated = styled(Typography)`
    position: absolute;
    top: 12px;
    width: 100%;
    white-space: nowrap;
    will-change: transform;
    animation: marquee 16s linear infinite;
    animation-direction: alternate;
    display: flex;
    gap: 20px;
    @keyframes marquee {
        from {
            transform: translateX(0);
        }
        to {
            transform: translateX(-200%);
        }
    }
`;

export const PlanMask = ({ children, variant, isSelected }: TProps) => {
    const symbols = new Array(50);
    symbols.fill(0);
    const getBgColor = useCallback(() => {
        if (isSelected) {
            return '#ff9494';
        }
        if (variant === 'dark') {
            return '#222';
        }
        return '#fff';
    }, [isSelected, variant]);
    const getSymbolImg = useCallback(() => {
        if (isSelected) {
            return symbolSelectedImg;
        }
        if (variant === 'dark') {
            return symbolDarkImg;
        }
        return symbolLightImg;
    }, [isSelected, variant]);
    return (
        <Wrapper bgColor={getBgColor()}>
            <Animated>
                <>
                    {symbols.map((_, index) => (
                        <img
                            key={index}
                            src={getSymbolImg()}
                            height={12}
                            width={8}
                            alt=""
                        />
                    ))}
                </>
            </Animated>
            <TitleTypography>Best value</TitleTypography>
            <PlanWrapper variant={variant}>{children}</PlanWrapper>
        </Wrapper>
    );
};
