import { useEffect, useState } from 'react';

import { Button } from '@shared/ui/atoms/button';
import { IconButton } from '@shared/ui/atoms/icon-button';
import { Chevron1Icon } from '@shared/ui/icon';
import { Logo } from '@shared/ui/molecules/logo';
import { ProgressBar } from '@shared/ui/molecules/progress-bar';
import { styled } from '@shared/ui/theme';

type Props = {
    isSkippable?: boolean;
    isReturnable?: boolean;
    isTransparent?: boolean;
    progress?: number;
    onSkip?: () => void;
    onReturn: () => void;
};

const Wrapper = styled.div<{ transparent?: boolean }>`
    position: sticky;
    z-index: 999;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 60px;
    background: ${({ transparent, theme }) =>
        transparent ? 'transparent' : theme.colors.background.primary};
    backdrop-filter: ${({ transparent }) =>
        transparent ? 'none' : 'blur(10px)'};
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 45px;
    width: 100%;
`;

const ProgressWrapper = styled.div`
    width: 50%;
    padding-bottom: 8px;
`;

const ButtonWrapper = styled.div`
    width: 80px;
`;

const SkipButtonWrapper = styled.div`
    width: 80px;
    display: flex;
    justify-content: flex-end;
`;

export const Header = ({
    isSkippable,
    onSkip,
    isReturnable,
    onReturn,
    isTransparent,
    progress,
}: Props) => {
    const [isWindowScrolled, setIsWindowScrolled] = useState<boolean>(false);

    useEffect(() => {
        const onScroll = () => setIsWindowScrolled(window.scrollY >= 30);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);
    return (
        <Wrapper transparent={isTransparent || !isWindowScrolled}>
            <ContentWrapper>
                <ButtonWrapper>
                    {isReturnable && (
                        <IconButton onClick={onReturn}>
                            <Chevron1Icon />
                        </IconButton>
                    )}
                </ButtonWrapper>
                <Logo />
                <SkipButtonWrapper>
                    {isSkippable && (
                        <Button variant="text" onClick={onSkip}>
                            Skip
                        </Button>
                    )}
                </SkipButtonWrapper>
            </ContentWrapper>
            {progress !== undefined && (
                <ProgressWrapper>
                    <ProgressBar progress={progress} />
                </ProgressWrapper>
            )}
        </Wrapper>
    );
};
