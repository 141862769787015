import { ReactNode } from 'react';
import styled from 'styled-components';

import { Typography } from '@shared/ui/typography';

type TProps = {
    icon: ReactNode;
    text: string;
    variant: 'dark' | 'light';
};

const Wrapper = styled.div<{ variant: 'dark' | 'light' }>`
    display: flex;
    align-items: center;
    border-radius: 8px;
    gap: 4px;
    padding: 0 8px;
    background-color: ${({ variant }) =>
        variant === 'light' ? '#e7fff5cc' : 'rgba(45, 82, 68, 0.80)'};
    color: ${({ variant }) => (variant === 'light' ? '#2DCE8A' : '#2DCE8ACC')};
    width: 160px;
`;

export const IconChip = ({ icon, text, variant }: TProps) => {
    return (
        <Wrapper variant={variant}>
            {icon}
            <Typography variant="button2">{text}</Typography>
        </Wrapper>
    );
};
