import { ReactNode } from 'react';

import { css, styled } from '@shared/ui/theme';

type Props = {
    HeaderComponent: ReactNode;
    HeroComponent: ReactNode;
    InfoComponent: ReactNode;
    CheckedComponent: ReactNode;
    PlanLightComponent: ReactNode;
    QuestionComponent: ReactNode;
    ReviewComponent: ReactNode;
    DarkPlanComponent: ReactNode;
    FooterComponent: ReactNode;
};

const CenteredMixin = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

const CenteredWrapper = styled.div`
    ${CenteredMixin}
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const H56 = styled.div`
    height: 56px;
`;

const CheckedWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 0 16px;
    max-width: 450px;
`;

const CoralSurfaceWrapper = styled.div`
    background-color: ${({ theme }) => theme.colors.background.coral};
    ${CenteredMixin}
`;

const ReviewWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 32px 12px;
    max-width: 450px;
`;

const SecondarySurfaceWrapper = styled.div`
    background-color: ${({ theme }) => theme.colors.surface.secondary};
    ${CenteredMixin}
`;

const DarkPlansWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 32px 16px;
    max-width: 450px;
`;

const DarkBgWrapper = styled.div`
    background-color: #222;
    ${CenteredMixin}
`;

const PlanWhiteWrapper = styled.div`
    padding: 32px 16px;
    max-width: 450px;
`;

const PrimaryBgWrapper = styled.div`
    background-color: #fdfdfd;
    ${CenteredMixin}
`;

const InfoWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 0 16px;
    max-width: 450px;
`;

const QuestionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 32px 16px;
    max-width: 450px;
`;

export const PaywallPageTemplate = ({
    HeaderComponent,
    HeroComponent,
    InfoComponent,
    CheckedComponent,
    PlanLightComponent,
    QuestionComponent,
    ReviewComponent,
    DarkPlanComponent,
    FooterComponent,
}: Props) => {
    return (
        <Wrapper>
            {HeaderComponent}
            {HeroComponent}
            <H56 />
            <CenteredWrapper>
                <InfoWrapper>{InfoComponent}</InfoWrapper>
            </CenteredWrapper>
            <H56 />
            <CenteredWrapper>
                <CheckedWrapper>{CheckedComponent}</CheckedWrapper>
            </CenteredWrapper>

            <H56 />
            <PrimaryBgWrapper>
                <PlanWhiteWrapper>{PlanLightComponent}</PlanWhiteWrapper>
            </PrimaryBgWrapper>
            <CoralSurfaceWrapper>
                <H56 />
                <QuestionWrapper>{QuestionComponent}</QuestionWrapper>
            </CoralSurfaceWrapper>
            <H56 />
            <SecondarySurfaceWrapper>
                <ReviewWrapper>{ReviewComponent}</ReviewWrapper>
            </SecondarySurfaceWrapper>
            <H56 />
            <DarkBgWrapper>
                <DarkPlansWrapper>
                    {DarkPlanComponent}
                    {FooterComponent}
                </DarkPlansWrapper>
            </DarkBgWrapper>
        </Wrapper>
    );
};
