import { useStore } from 'effector-react';
import { ReactNode } from 'react';

import { useGetStage } from '@entities/questions/model/hooks/use-get-stage';
import { $stage } from '@entities/questions/model/questions-domain';
import { styled } from '@shared/ui/theme';

type Props = {
    children: ReactNode;
    isWhiteBg?: boolean;
};

const Wrapper = styled.div<{ isWhiteBg: boolean }>`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    background: ${({ theme, isWhiteBg }) =>
        isWhiteBg
            ? theme.colors.background.primary
            : theme.colors.background.coral};
`;

const ContentWrapper = styled.div`
    max-width: 450px;
    width: 100%;
`;

export const PageTemplate = ({ children, isWhiteBg }: Props) => {
    const stage = useStore($stage);
    const stageData = useGetStage(stage);
    return (
        <Wrapper isWhiteBg={isWhiteBg || stageData?.type === 'QUESTION'}>
            <ContentWrapper>{children}</ContentWrapper>
        </Wrapper>
    );
};
