import { useTheme } from 'styled-components';

import { StarIcon } from '@shared/ui/icon';
import { styled } from '@shared/ui/theme';
import { Typography } from '@shared/ui/typography';

type TProps = {
    stars: number;
    nickname: string;
    title: string;
    description: string;
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 12px;
    min-width: 200px;
    border-radius: 4px 16px 16px 16px;
    background-color: ${({ theme }) => theme.colors.background.primary};
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const StarsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 3px;
`;

export const ReviewCard = ({ stars, nickname, title, description }: TProps) => {
    const theme = useTheme();
    const starArr = new Array(stars).fill(0);
    return (
        <Wrapper>
            <ContentWrapper>
                <HeaderWrapper>
                    <StarsWrapper>
                        {starArr.map(_ => (
                            <StarIcon />
                        ))}
                    </StarsWrapper>
                    <Typography
                        variant="caption1"
                        color={theme.colors.text.secondary}>
                        {nickname}
                    </Typography>
                </HeaderWrapper>
                <Typography
                    variant="body1"
                    fontWeight={600}
                    color={theme.colors.text.primary}>
                    {title}
                </Typography>
            </ContentWrapper>
            <Typography variant="caption1" color={theme.colors.text.secondary}>
                {description}
            </Typography>
        </Wrapper>
    );
};
